.generalsite-layout {

  .request-general {
    padding-bottom: 0px;
    box-shadow: none;

    .section-head {
      font-size: 14px;
      letter-spacing: -0.14px;
      line-height: 26px;
      color: $primary;
      margin-bottom: 10px;

      @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
        letter-spacing: -0.13px;
        line-height: 24px;
        margin-bottom: 6px;
      }
    }

    .border-full-line {
      margin-top: 26px !important;
      margin-bottom: 26px !important;

      @media screen and (max-width: $min-md - 1) {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
      }
    }

    .report-range-result {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      h3 {
        font-size: 14px;
        letter-spacing: -0.14px;
        color: $primary;
        margin: 0px;
      }

      .range-result {
        font-family: 'cairo-semibold', sans-serif;
        color: $dark-grey;
        font-size: 14px;
        letter-spacing: -.14px;
        padding: 0 14px;
      }
    }
  }

  // cards item
  .cards-container {
    .cards-tabs {
      margin-left: -26px;

      @media screen and (max-width: $min-lg - 1) {
        margin-left: -12px;
      }

      .add-new {
        background: $lighter-grey-2 !important;
        border: 1px dashed rgba($color: $black, $alpha: .1) !important;
        box-shadow: none !important;
        display: flex;
        border-radius: 10px;
        padding-top: 12px;

        .btn-card {
          width: 100%;
          padding: 14px 4px 0px;

          &:focus {
            outline: none;
          }

          p {
            color: $dark-grey;
            font-family: 'cairo-semibold', sans-serif;
            font-size: 14px;
            letter-spacing: -0.14px;
            height: 45px;
            line-height: 20px;
            display: flex;
            align-items: center;
            padding: 0px 2px;
            white-space: normal;

            @media screen and (max-width: $min-md - 1) {
              font-size: 13px;
              letter-spacing: -0.13px;
              line-height: 16px;
              height: 40px;
            }
          }
        }
      }

      .nav-tabs {
        overflow: unset;
        padding: 10px 0px;

        .nav-item {
          min-width: 110px;
          width: 110px;
          height: 110px;
          margin-left: 16px;

          @media screen and (max-width: $min-md - 1) {
            min-width: 90px;
            width: 90px;
            height: 90px;
            margin-left: 12px;
          }

          .nav-link {
            padding: 0px;
            color: $dark-grey;
            border: 1px solid $light-grey-2;
            background: $white;
            border-radius: 10px;
            text-align: center;
            width: 100%;
            height: 100%;
            box-shadow: 0px 2px 2px rgba($color: $black, $alpha: 0.08);
            position: relative;

            .card-request {
              height: 100%;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              padding: 14px 4px 0px;
              box-shadow: 0px 2px 2px rgba($black, 0.08);
              border-radius: 10px;

              svg {
                width: 34px;
                height: 34px;

                @media screen and (max-width: $min-md - 1) {
                  width: 26px;
                  height: 26px;
                }

                rect {
                  fill: transparent;
                }

                g {
                  fill: transparent;
                }

                path {
                  fill: $dark-grey;
                }
              }

              p {
                font-family: 'cairo-semibold', sans-serif;
                font-size: 14px;
                letter-spacing: -0.14px;
                margin: 0px;
                overflow: hidden;
                color: $dark-grey;
                height: 45px;
                line-height: 20px;
                display: flex;
                align-items: center;
                white-space: normal;

                @media screen and (max-width: $min-md - 1) {
                  font-size: 13px;
                  letter-spacing: -0.13px;
                  line-height: 16px;
                  height: 40px;
                }
              }

              .card-done {
                display: none;
                position: absolute;
                top: 10px;
                right: 10px;

                svg {
                  width: 18px;
                  height: 18px;

                  path {
                    fill: $light-green;
                  }

                  @media screen and (max-width: $min-md - 1) {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }

            &.active {
              background: $primary;
              color: $white;

              svg {
                path {
                  fill: $white !important;
                }
              }

              p {
                color: $white;
              }
            }
          }

          .card-removed {
            position: absolute;
            top: -10px;
            left: -10px;
            cursor: pointer;
            display: none;
          }

          &:hover {
            .card-removed {
              display: block;
            }
          }
        }
      }
    }

    .tab-content {
      box-shadow: none;
    }

    .performance-section {
      @media screen and (max-width: $min-md - 1) {
        margin: -15px -15px 0px;
      }
    }

    .electric-meter-section {
      @media screen and (max-width: $min-md - 1) {
        margin: 0px -15px;

        h6 {
          margin: 0px 15px 16px;
        }
      }
    }

    .tab-pane {
      .nav-tabs {
        border-bottom: 0px;

        .nav-link {
          height: 42px;
          min-width: 120px;
          font-size: 14px;
          font-family: 'cairo-semibold', sans-serif;
          letter-spacing: -0.14px;
          border-radius: 10px !important;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: $min-md - 1) {
            font-size: 13px;
            letter-spacing: -0.13px;
            min-width: 133px;
          }

          &.btn-available {
            margin-left: 16px;

            @media screen and (max-width: $min-md - 1) {
              margin-left: 10px;
            }

            &.active {
              background: $light-green !important;
              border-color: $light-green !important;
              color: $white !important;
            }
          }

          &.btn-closed {
            &.active {
              background: $error !important;
              border-color: $error !important;
              color: $white !important;
            }
          }

        }

        .btn-outline-light-grey-2 {
          border-width: 1.5px !important;

          &:hover {
            background: transparent !important;
          }
        }
      }
    }

    .trouble-section {

      .trouble-container {

        @media screen and (max-width: $min-md - 1) {
          margin: 0px -15px 14px;
        }

        .trouble-item {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0px;
          }

          .trouble-box {
            border: 1px solid $error;
            background: rgba($color: $error, $alpha: .2);
            padding: 0rem 1rem;
            border-radius: 10px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: $min-md - 1) {
              height: 46px;
              padding: 0px 10px;
            }

            .trouble-name {
              color: $error;
              font-size: 14px;
              letter-spacing: -0.14px;
              margin: 0px !important;

              @media screen and (max-width: $min-md - 1) {
                font-size: 13px;
              }
            }

            .trouble-images {
              display: flex;

              img {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                margin: 0px .2rem;

                @media screen and (max-width: $min-md - 1) {
                  width: 32px;
                  height: 32px;
                }
              }

              .dropdown-mob-item {
                display: none;
                align-items: center;
                margin-right: 8px;

                .dropdown {
                  .dropdown-toggle {
                    &:focus {
                      outline: 0 !important;
                    }
                  }
                }

                .dropdown-toggle {
                  display: flex;

                  svg {
                    fill: $error;
                    width: 24px;
                    height: 24px;

                    @media screen and (max-width: $min-md - 1) {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }

                .dropdown-menu {
                  &.show {
                    width: 104px;
                    display: flex;
                    padding: 8px 0px;
                    box-shadow: 0px 2px 8px rgba($black, 0.08);

                    top: 12px !important;
                    left: -11px !important;
                    border: 1px solid $light-grey-2;

                    &::before,
                    &::after {
                      content: '';
                      display: block;
                      position: absolute;
                      bottom: 100%;
                      width: 0;
                      height: 0;
                    }

                    &::before {
                      left: 8px;
                      border: 11px solid transparent;
                      border-bottom-color: $light-grey-2;
                    }

                    &::after {
                      left: 9px;
                      border: 10px solid transparent;
                      border-bottom-color: $white;
                    }

                    button {
                      img {
                        width: 34px;
                        height: 34px;
                        border-radius: 0px;
                      }

                      .edit-btn {
                        border: 1px solid $light-blue;
                        border-radius: 10px;
                      }
                    }
                  }

                  button {
                    border: 0px;
                    background: transparent;
                  }
                }

                @media screen and (max-width: $min-lg - 1) {
                  display: flex;
                }
              }
            }
          }

          .trouble-buttons {
            display: flex;
            justify-content: space-between;
            margin-right: -18px;

            .btn {
              padding: 0px;
              min-width: auto;
              width: 60px;
              height: 60px;
              box-shadow: none;

              @media screen and (max-width: $min-lg - 1) {
                display: none;
              }

              img {
                width: 100%;
                height: 100%;
              }

              // .edit-btn{
              //     border: 1px solid $light-blue;
              //     border-radius: 10px;
              // }
            }
          }
        }
      }
    }

    .electric-meter-btn {
      box-shadow: none;
    }

    .card-tab-footer {
      display: flex;
      justify-content: space-between;
      margin: 0px -26px;
      margin-top: 22px !important;
      padding-top: 26px;
      background-color: $light;

      @media screen and (max-width: $min-md - 1) {
        margin: 0px -12px;
        padding-top: 16px;
      }

      @media screen and (max-width: $min-sm - 1) {
        .btn {
          min-width: 140px;
        }
      }
    }
  }

  // modals
  .custom-checkbox {
    margin-bottom: 20px;
  }

  // add new trouble modal
  .trouble-modal {
    .modal-dialog {
      @media screen and (min-width: $min-md - 1px) {
        min-width: 712px;
        max-width: 712px;
      }

      .table-responsive {
        box-shadow: none;
      }

      .modal-body {
        .search-bar {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 100%;

          .dataTables_filter {
            label {
              display: block;
              width: 100%;
            }

            .search-query {
              margin-left: 0px;
              margin-right: 0px;
              background: $lighter-grey-2;
            }
          }
        }

        .nav-tabs {
          margin-top: 60px;
          margin-bottom: 20px;

          @media screen and (max-width: $min-md - 1px) {
            margin-top: 54px;
            margin-bottom: 12px;
          }

          .nav-item {
            min-width: 50%;
            padding: 0px;
            text-align: center;
            height: 43px;
            border-radius: 0px 10px 10px 0px;
            overflow: hidden;
            border: 1px solid $error;

            &:last-child {
              border-radius: 10px 0px 0px 10px;
            }

            .nav-link {
              padding: 0px;
              background: $white;
              color: $error;
              border-radius: 0px;
              height: 42px;
              line-height: 40px;
              font-size: 14px;
              letter-spacing: -0.14px;

              @media screen and (max-width: $min-md - 1) {
                font-size: 13px;
              }

              &:hover {
                border-color: transparent;
              }

              &.active {
                background: $error;
                color: $white;
                border: 0px;
              }
            }
          }
        }

        .tab-content {
          min-height: 240px;
          box-shadow: none;

          .tab-pane {
            .dataTables_wrapper {
              .dataTables_scroll {

                .dataTables_scrollHead {
                  border-top-right-radius: 10px;
                  border-top-left-radius: 10px;

                  .dataTables_scrollHeadInner {
                    width: 100% !important;

                    table {
                      width: 100% !important;
                      border-radius: 10px 10px 0px 0px;

                      thead {
                        tr {
                          th {
                            min-width: 120px;
                            font-size: 14px;
                            color: $primary;
                            height: 40px;
                            line-height: 38px;
                            padding: 0px;
                            padding-right: 30px !important;
                            pointer-events: none;

                            @media screen and (max-width: $min-md - 1) {
                              font-size: 13px;
                              min-width: 80px;
                              padding-right: 14px !important;
                              line-height: 20px;
                            }

                            &::after {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .dataTables_scrollBody {
                  box-shadow: none;
                  max-height: 220px !important;
                  border: 1px solid rgba($color: $black, $alpha: .1);
                  border-top: 0px;
                  border-radius: 0px 0px 10px 10px;
                  overflow-x: hidden;

                  // table scroll
                  &::-webkit-scrollbar {
                    width: 8px;
                    border-radius: 5px;
                  }

                  &::-webkit-scrollbar-track {
                    background-color: $scroll-track;
                    border-radius: 5px;
                  }

                  &::-webkit-scrollbar-thumb {
                    background-color: $grey;
                    border-radius: 5px;
                  }

                  table {
                    thead {
                      display: none;
                    }

                    tbody {
                      tr {
                        td {
                          min-width: 120px;
                          padding-right: 18px !important;
                          padding-left: 0px;
                          color: $dark-grey;
                          font-size: 14px;
                          letter-spacing: -0.14px;
                          font-family: 'cairo-regular', sans-serif;

                          @media screen and (max-width: $min-md - 1px) {
                            min-width: auto;
                            padding-right: 8px !important;
                            font-size: 13px;
                          }

                          &.sorting_1 {
                            @media screen and (max-width: $min-md - 1px) {
                              min-width: auto;
                              display: flex;
                            }
                          }

                          .custom-radio {
                            .custom-control-label {
                              padding-right: 32px;
                              font-family: 'cairo-bold', sans-serif;

                              &::before {
                                right: 6px;
                              }

                              &::after {
                                right: 10px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .footer-modal {
          justify-content: flex-end;

          @media screen and (max-width: $min-md - 1px) {
            justify-content: space-between;
          }

          .btn {
            min-width: 30%;
            margin-right: 16px;

            @media screen and (max-width: $min-md - 1px) {
              min-width: 48%;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  // trouble data modal
  .trouble-data-modal {
    .modal-dialog {
      @media screen and (min-width: $min-md - 1px) {
        min-width: 712px;
        max-width: 712px;
      }
    }

    .trouble-data-name {
      color: $primary;
      font-size: 14px;
      letter-spacing: -0.14px;
      align-items: center;

      @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
        letter-spacing: -0.13px;
      }
    }

    .trouble-data-content {
      color: $dark-grey;
      font-family: 'cairo-regular', sans-serif;
      font-size: 14px;
      letter-spacing: -0.14px;

      @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
        letter-spacing: -0.13px;
      }
    }

    .trouble-images-container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      /* Hide scrollbar for IE and Edge */
      -ms-overflow-style: none;

      /* Firefox 64 */
      scrollbar-width: none;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .add-new {
        width: 100px;
        min-width: 100px;
        height: 100px;
        padding: 0px;
        margin-left: 12px;

        @media screen and (max-width: $min-md - 1px) {
          width: 80px;
          min-width: 80px;
          height: 80px;
        }

        .btn-card {
          padding: 0px;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .trouble-data-images {
        display: flex;
        margin-bottom: 10px;

        .image-link-item {
          width: 100px;
          min-width: 100px;
          height: 100px;
          margin-left: 12px;
          position: relative;

          @media screen and (max-width: $min-md - 1px) {
            width: 80px;
            min-width: 80px;
            height: 80px;
          }

          &:hover .delete-image-link {
            display: flex;
          }

          .delete-image-link {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: none;
            justify-content: center;
            align-items: center;
            background: rgba($color: #111934, $alpha: .5);

            button {
              background: transparent;
              border: 0px;
              padding: 0px;

              &:focus {
                outline: none;
              }

              img {
                height: 24px;
              }
            }
          }
        }
      }
    }

    .modal-body {

      .footer-modal {
        justify-content: flex-end;

        @media screen and (max-width: $min-md - 1px) {
          justify-content: space-between;
          margin: 0px 10px;
        }

        .btn {
          min-width: 30%;
          margin-right: 16px;

          @media screen and (max-width: $min-md - 1px) {
            min-width: 48%;
            margin-right: 0px;
          }
        }
      }
    }
  }

  // image link
  .image-link {

    .modal-dialog {
      @media screen and (min-width: $min-md - 1px) {
        min-width: 712px;
        max-width: 712px;
      }
    }

    .modal-body {
      .footer-modal {
        justify-content: flex-end;

        @media screen and (max-width: $min-md - 1px) {
          justify-content: space-between;
        }

        .btn {
          min-width: 30%;
          margin-right: 16px;

          @media screen and (max-width: $min-md - 1px) {
            min-width: 48%;
            margin-right: 0px;
          }
        }
      }

      .images-link-content {
        @media screen and (min-width: $min-md - 1px) {
          min-height: 230px;
        }

        ul {
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          margin-left: -14px;

          li {
            display: inline-block;
            margin: 0px 0px 12px 12px;

            input[type="checkbox"] {
              display: none;
            }

            label {
              display: block;
              position: relative;
              cursor: pointer;
              margin: 0px;
              overflow: hidden;
              border-radius: 10px;
              background: transparent;

              &::before {
                content: '';
                background: transparent;
                border: 0px;
              }

              &::after {
                content: '';
                background: transparent;
                border: 0px;
              }

              img {
                height: 100px;
                width: 100px;
                border-radius: 10px;

                @media screen and (max-width: $min-md - 1px) {
                  height: 80px;
                  width: 80px;
                }
              }
            }
          }
        }

        :checked+label:before {
          content: "\f00c";
          font-family: "Font Awesome 5 Solid", sans-serif;
          position: absolute;
          top: 36px;
          right: 38px;
          border-radius: 50%;
          border: 2px solid $white;
          color: $white;
          font-size: 14px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;

          @media screen and (max-width: $min-md - 1px) {
            top: 32px;
            right: 30px;
            font-size: 8px;
            width: 20px;
            height: 20px;
          }
        }

        :checked+label:after {
          content: '';
          position: absolute;
          top: 0px;
          right: 0px;
          width: 100%;
          height: 100%;
          background: rgba($color: #A0A3AE, $alpha: .5);
          border: 3px solid #0885C2;
          border-radius: 10px;
          z-index: 1;
        }
      }
    }
  }
}