.request-details-layout {

    // request status
    .req-status {
        display: flex;
        justify-content: space-between;
        padding: 15px 26px;

        .req-box {
            display: flex;
            justify-content: space-between;
            min-width: 50%;
            align-items: center;

            .req-title {
                min-width: 50%;
                color: $primary;
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 26px;
            }

            .req-data {
                min-width: 50%;
                color: $dark-grey;
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 26px;
                font-family: "cairo-regular", sans-serif;

                @media screen and (max-width: $min-sm - 1) {
                    text-align: center;
                }
            }
        }

        @media screen and (max-width: $min-md - 1) {
            padding: 13px 12px !important;

            .req-box {
                min-width: 50%;

                .req-title {
                    min-width: 50%;
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    line-height: 24px;
                }

                .req-data {
                    min-width: 50%;
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    line-height: 24px;
                }
            }
        }
    }
}

// request container
.req-container {

    // tabs content
    .tab-content {
        padding: 0px;
    }

    .unborder {
        border-top-right-radius: 0px;

        @media screen and (max-width: $min-xs - 1) {
            border-top-left-radius: 0px;
        }
    }
}