header {
    margin-bottom: 60px;
    background: $white;
    z-index: 1001;

    @media screen and (max-width: $min-lg - 1) {
        margin-bottom: 0px;
        position: fixed;
        width: 100%;
    }

    .navbar-header{
        background: $white;
        box-shadow: 0px 2px 8px rgba($black, 0.08);
        height: 100px;

        @media screen and (max-width: $min-lg - 1) {
            height: 60px;
        }

        // add nav fixed style to nav when scroll in desktop view
        @media screen and (min-width: $min-lg - 1) {
            &.nav-fixed{
                height: 70px;
                position: fixed;
                top: 0px;
                right: 0px;
                left: 0px;
                z-index: 1030;

                .navbar {
                    height: 70px;
            
                    .navbar-brand {
                        height: 50px;
                    }
                }
            }
        }
    }

    // start top header
    .top-header {
        height: 42px;
        background-color: $yellow;

        @media screen and (max-width: $min-lg - 1) {
            display: none;
        }
    }

    // social media in top header
    .social-media {
        text-align: left;
        line-height: 42px;

        ul {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 42px;

            li {
                display: inline-block;
                padding-right: 16px;

                &.phone-number{
                    pointer-events: none;

                    a{
                        span{
                            padding-bottom: 0px;
                            font-family: 'cairo-regular', sans-serif;
                            letter-spacing: -0.14px;
                            color: $primary;
                            display: inline-block;
                            line-height: initial;
                            font-size: 14px;
                            margin-left: 8px;
                            direction: ltr;
                        }
                    }
                }

                a {
                    height: 18px;
                    display: flex;
                    align-items: center;

                    img {
                        height: 100%;
                    }

                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    // end top header

    // start navbar in all pages
    .navbar {
        padding: 0;
        height: 100px;

        .navbar-brand {
            margin: 0px;
            padding: 0px;
            width: 137px;
            height: 70px;

            img {
                width: 80%;
                height: 100%;
            }
        }

        .navbar-toggler {
            border: 0px;
            padding: 0px;
            margin-left: 8px;
            width: 20px;
            height: 20px;
            display: none;

            img{
                width: 100%;
                height: 100%;
            }

            &:focus {
                outline: none;
            }
        }

        .navbar-collapse {
            .menu-back {
                display: none;
            }

            .avatar-card {
                display: none;

                .navbar-nav {
                    padding-right: 0px;
                }
            }

            .user-sign-action{
                display: none;
            }
        }

        .navbar-actions {
            margin-right: auto;
            display: flex;
            align-items: center;

            .notification {
                position: relative;
                margin: 0px 40px;

                @media screen and (max-width: $min-lg - 1) {
                    margin: 0px 20px;
                }

                .dropdown {
                    .nav-link{
                        img{
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .dropdown-menu {
                        min-width: 400px;
                        left: -170px;
                        top: 60px;
                        max-height: 400px;
                        overflow-y: auto;
                        position: absolute;
                        padding: 0px;
                        box-shadow: 0px 1px 4px rgba(17, 25, 52, 0.14);
                        
                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 100%;
                            width: 0;
                            height: 0;
                        }
    
                        &::before {
                            left: 12px;
                            border: 11px solid transparent;
                            border-bottom-color: #E7E8EB;
                        }
    
                        &::after {
                            left: 13px;
                            border: 10px solid transparent;
                            border-bottom-color: $white;
                        }

                        .notification-item {
                            border-bottom: 1px solid $light;
                            white-space: unset;
                            padding: 16px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            &:last-child {
                                border-bottom: 0px;
                            }

                            .notification-content {
                                color: $dark-grey;
                                font-size: 14px;
                                letter-spacing: -0.14px;
                                font-family: 'cairo-regular', sans-serif;
                                margin-bottom: .4rem;
                            }

                            span {
                                color: $grey;
                                display: inline-block;
                                font-family: 'cairo-semibold', sans-serif;
                                font-size: 14px;
                                padding: 0px;
                            }
                        }

                        @media screen and (max-width: $min-lg - 1) {
                            min-width: 290px;
                            left: -60px;
                            top: 40px;
                        }
                    }
                }

                .notification-count {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    border-radius: 10px;
                    background: $yellow;
                    color: $primary;
                    font-size: 14px;
                    letter-spacing: -0.14px;
                    font-family: 'cairo-bold', sans-serif;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: $min-lg - 1) {
                        font-size: 12px;
                        height: 18px;
                        width: 18px;
                        top: -8px;
                        right: -8px;
                    }
                }
            }

            .dropdown {
                .nav-link {
                    font-size: 14px;
                    letter-spacing: -0.14px;
                    color: $primary;
                    padding-left: 0px;

                    &::after {
                        display: none;
                    }

                    span{
                        @media screen and (max-width: $min-lg - 1) {
                            display: none;
                        }
                    }

                    .avatar-icon {
                        display: inline-block;
                        margin-left: 0rem;

                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 5px;
                            margin-right: 12px;

                            @media screen and (max-width: $min-lg - 1) {
                                width: 28px;
                                height: 28px;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    left: 0px;
                    border: 0px;
                    bottom: auto;
                    top: 70px;
                    background: $white;
                    text-align: right;
                    min-width: 300px;
                    min-height: 54px;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 10px;
                    box-shadow: 0px 1px 4px #11193424;

                    &::before,
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 100%;
                        width: 0;
                        height: 0;
                    }

                    &::before {
                        left: 12px;
                        border: 11px solid transparent;
                        border-bottom-color: #E7E8EB;
                    }

                    &::after {
                        left: 13px;
                        border: 10px solid transparent;
                        border-bottom-color: $white;
                    }

                    .dropdown-item {
                        font-size: 14px;
                        font-family: 'cairo-semibold', sans-serif;
                        color: $primary;
                        letter-spacing: -0.14px;
                        display: flex;
                        align-items: center;
                        min-height: 54px;
                        padding: 0px 26px;

                        &:active {
                            background: transparent;
                            color: initial;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover{
                            border-radius: 10px;
                            background: transparent;
                        }

                        img {
                            width: 20px;
                            height: 20px;
                            margin-left: 16px;
                        }
                    }
                }
            }

            @media screen and (max-width: $min-lg - 1) {
                .notification{
                    order: 2;

                    .dropdown {
                        .nav-link{
                            img{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .avatar-name{
                    order: 1;

                    .dropdown-menu{
                        display: none;
                    }
                }

                .navbar-toggler{
                    order: 3;
                }
            }
        }

        @media screen and (max-width: $min-lg - 1) {
            height: 60px;

            .navbar-brand {
                height: 44px;
                 width: 80px;
            }

            .navbar-toggler{
                display: flex;
            }

            .navbar-collapse{
                position: fixed;
                top: 0;
                right: -100%;
                background: $white;
                width: 100%;
                height: 100vh;
                z-index: 10000;

                &.show {
                    right: 0px;
                    transition: right .2s cubic-bezier(0, 0, 0.35, 1.13);
                }

                .menu-back {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    border: 0px;
                    padding: 0px;
                    margin: 0px;
                    height: 60px;
                    margin-left: 25px;

                    .close-menu{
                        width: 20px;
                        height: 20px;
                        display: flex;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .avatar-card{
                    display: flex;
                    align-items: center;
                    border-top: 1px solid $light;
                    border-bottom: 1px solid $light;
                    border-radius: 0px;
                    padding: 15px 30px;

                    img{
                        height: 60px;
                        width: 60px;
                    }

                    .avater-name{
                        font-size: 16px;
                        margin: 0;
                        margin-right: 16px;
                    }
                }

                .user-sign-action{
                    padding: 26px;
                    text-align: center;
                    display: block;

                    .btn{
                        min-width: 276px;
                        margin: auto;
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
    // end navbar

    // start main navbar in login pages
    .main-nav {
        &.navbar {
            justify-content: space-between;

            .navbar-nav {
                
                .nav-item {
                    border-radius: 10px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    padding: 0px 16px;

                    .nav-link {
                        font-size: 14px;
                        letter-spacing: -0.14px;
                        color: $primary;
                        padding: 0px;
                    }
                }

                .user-account {
                    display: flex;
                    align-items: center;
                    margin-right: .7rem;

                    .sign-in {
                        margin-right: .3rem;
                        min-width: 176px;
                        height: 40px;
                        box-shadow: none;
                    }
                }
            }

            @media screen and (max-width: $min-lg - 1) {
                .navbar-collapse{
                    overflow-y: auto;
                }

                .navbar-nav{
                    padding-right: 0;
                    display: inline-block;
                    width: 100%;
                    
                    .nav-item {
                        padding: 0px;
                        border-top: 1px solid $light;
                        border-bottom: 1px solid $light;
                        border-radius: 0px;
                        height: 52px;
                        margin-top: -1px;

                        .nav-link {
                            padding: 13px 26px;
                            font-size: 13px;
                            letter-spacing: -0.13px;
                        }                    
                    }

                    .user-account {
                        display: block;
                        text-align: center;
                        padding-top: 26px;
                        margin-right: 0px;

                        .new-account{
                            border: 0px;
                            margin-bottom: 12px;
                            display: inline-flex;

                            &:hover{
                                background: transparent;
                            }

                            .nav-link{
                                display: inline-flex;
                                align-items: center;
                                border: 2px solid;
                                border-radius: 10px;
                                height: 42px;
                                min-width: 276px;
                                font-size: 13px;
                                letter-spacing: -0.13px;
                                justify-content: center;
                            }
                        }

                        .sign-in {
                            height: 42px;
                            min-width: 276px;
                            font-size: 13px;
                            letter-spacing: -0.13px;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
    // end main navbar
}
