/* You can add global styles to this file, and also import other style files */
// components
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import "assets/styles/components/components";

// pages
@import "assets/styles/pages/pages";
@import "~@ng-select/ng-select/themes/default.theme.css";
html {
  position: relative;
  height: 100%;
}

body {
  background-color: $grey-6;
  height: 100%;
  margin: 0;
}
.mat-mdc-form-field-infix,
.mat-mdc-form-field-flex {
  padding: 0 !important;
  min-height: 42px !important;
}
.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-input-control {
  height: 42px !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading{
  border: transparent;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border: transparent;
}
.mat-date-range-input-container {
  height: 42px;
}
.mat-mdc-text-field-wrapper {
  height: 42px !important;
  border: 1px solid #e7e8eb !important;
  border-radius: 5px !important;
  background-color: #f3f3f5 !important;
}
.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
  padding: 0 !important;
  height: 42px !important;
  width: 42px !important;
}

.mat-icon {
  width: fit-content !important;
  height: fit-content !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-wrapper {
  padding-bottom: 0;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-flex {
  background: $grey-6;
  border: 1px solid #e7e8eb;
  border-radius: 5px;
  height: 42px;
}
.rb-date-picker.placeholder:focus::before {
  width: 0%;
  content: "";
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-suffix {
  bottom: 10px;
  left: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-suffix:focus {
  outline: none;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
  bottom: 10px;
  right: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-underline,
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  visibility: hidden;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.2em 0.2em 0 0.75em !important;
  border-radius: 5px !important;
  background: #f3f3f5 !important;
}
.panel-body {
  padding: 20px;

  .custom-card {
    margin: 0;
    box-shadow: none;
    padding: 20px 15px;
  }
}
.ng-trigger-transformPanel.mat-mdc-select-panel {
  background: #f3f3f5;
  box-shadow: 0px 10px 13px #00000014;
  border: 1px solid #0885c2;
  border-radius: 10px;
  min-width: calc(100% + 12px) !important;
  // margin: 42px 29px;
  position: absolute;
  top: 42px;
  right: 29px;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-text,
.mat-select-placeholder {
  font-family: "cairo-regular", sans-serif !important;
  font-size: 14px;
  color: #707585;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-placeholder {
  display: inline-block;
  height: 22px;
  position: absolute;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value-text .ng-star-inserted {
  font-family: "cairo-regular", sans-serif !important;
  font-size: 14px;
  color: #707585 !important;
  display: block;
  position: absolute;
}
.mat-mdc-input-element:disabled {
  padding-top: 5px;
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-selected .mat-option-text {
  color: #707585 !important;
  font-family: "cairo-bold", sans-serif !important;
}
[dir="rtl"] .mat-mdc-option-pseudo-checkbox,
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background: #fff;
  border-color: #b4b6bf !important;
}
.mat-pseudo-checkbox-checked::after {
  border-color: #0774a9 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value-text {
  font-family: "cairo-regular", sans-serif !important;
  font-size: 14px;
  color: #707585 !important;
  line-height: 21px;
}

.switch-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px !important;
  .switch-desc {
    color: #a0a3ae;
    font-size: 13px;
    margin-right: 5px;
  }
  .switch {
    display: inline-block;
    width: 50px;
    height: 14px;
    margin: 5px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    position: relative;
    input {
      display: none;
    }
    .slider {
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 20px;
      background: #dee1ec;
      cursor: pointer;
      border: 4px solid transparent;
      overflow: visible;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        width: 22px;
        height: 22px;
        background: #5b6175;
        border-radius: 100px;
        top: -8px;
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(-22px);
  transform: translateX(-22px);
  background: #379b79;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  &:after {
    content: "";
    top: -1px !important;
    left: -1px !important;
  }
  border: 1px solid #dee1ec;
  border-radius: 4px;
  input {
    height: 16px;
    width: 16px;
  }
}

.sentry-error-embed .form-field label {
  float: right;
}
