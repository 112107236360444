.dataTables_wrapper {
  &.form-inline{
    display: block;
  }
}

.table.dataTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-collapse: collapse !important;

  thead {
    tr{
      th{
        &.sorting , &.sorting_desc{
          padding-right: 26px;

          @media screen and (max-width: $min-md - 1) {
            padding-right: 12px;
          }
        }
      }
    }

    // show sort arrows 
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      cursor: pointer;
      position: relative;

      &:after {
        position: absolute;
        bottom: 18px;
        left: auto;
        right: calc(148px - 46px);
        display: block;
        width: 18px;
        height: 18px;
        padding-right: 6px;
        opacity: 1;

        @media screen and (max-width: $min-md - 1) {
          right: calc(148px - 66px);
          bottom: 14px;
        }
      }
    }

    .sorting:after {
      opacity: 0;
      content: url("../../images/svg/sort-arrows-icon.svg");
      /* sort */
    }

    .sorting_asc:after {
      content: url("../../images/svg/sort-arrows-icon.svg");
      /* sort-by-attributes */
    }

    .sorting_desc:after {
      content: url("../../images/svg/sort-arrows-icon.svg");
      /* sort-by-attributes-alt */
    }
  }
}

// data table search
.dataTables_filter {
  display: flex;
  justify-content: space-between;

  label {
    margin: 0px;
    min-width: calc(100% - 145px);

    input.form-control {
      background: $white;
    }
  }

  .btn {
    min-width: 125px;
  }

  @media screen and (max-width: $min-md - 1) {
    label {
      min-width: calc(100% - 110px);
    }

    .btn {
      min-width: 100px;
    }
  }
}