.mat-datepicker-content .mat-calendar {
    width: 350px !important;
    height: auto !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 2px #00000014 !important;
    border-radius: 5px !important;
  }
  .mat-calendar-body-label {
    visibility: hidden !important;
  }
  .mat-calendar-body-label[colspan='7'] {
    display: none !important;
  }
  .mat-calendar-spacer {
    display: none;
  }
  .mat-calendar-header {
    padding: 0 !important;
  }
  .mat-calendar-controls {
    border: 2px solid #f3f3f5;
    margin: 20px 30px !important;
    border-radius: 10px;
    height: 44px;
  
    button {
        display: flex;
        justify-content: center;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-calendar-controls span.mat-button-wrapper {
    font-size: 16px;
    font-family: "cairo-bold", sans-serif;
    position: absolute;
    // right: -8px;
  }
  .mat-mdc-icon-button {
    margin-top: -1px;
    &:focus{
      outline: none !important;
    }
  }
  .mat-calendar-next-button {
    position: absolute !important;
    left: 30px;
    background: #cfd1d6;
    border-radius: 0 !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .mat-calendar-previous-button {
    position: absolute !important;
    right: 30px;
    background: #cfd1d6;
    border-radius: 0 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .mat-calendar-period-button {
    right: 35%;
    left: 35%;
    width: 30%;
  }
  .mat-calendar-arrow {
    display: none !important;
  }
  
  th.ng-star-inserted {
    color: #707585;
    font-family: "cairo-bold", sans-serif;
  }
  
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected), .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: $green-dark;
    color: #fff;
    border-radius: 5px  !important;
  }
  .mat-calendar-body-cell-content{
    width: 75% !important;
    height: 75% !important;
  }
  
  td.mat-calendar-body-cell.mat-focus-indicator.ng-star-inserted{
    width: 36px !important;
  }
  
  .mat-calendar-body-cell-content:hover,
  .mat-calendar-body-cell.mat-focus-indicator:hover {
    border-radius: 5px !important;
  }
  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    border-radius: 5px;
    background-color: $green-dark;
    color: #fff;
  }
  .mat-calendar-body-today {
    border-radius: 5px !important;
    // background-color: #111934;
    // color: #fff;
  }