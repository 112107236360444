body {
  direction: rtl;
  text-align: right;
  background-color: $light;
  font-family: 'cairo-bold', sans-serif;

  html[dir='ltr'] &,
  html[lang='en'] & {
    font-family: 'cairo-bold', sans-serif;
    direction: ltr;
    text-align: left;
  }

  &.modal-open {
    padding: 0px !important;
  }


  main {
    @media screen and (max-width: $min-lg - 1) {
      padding-top: 72px;

    }

    &.login-layout {
      @media screen and (max-width: $min-lg - 1) {
        padding-top: 90px;
      }
    }

    @media screen and (max-width: $min-md - 1) {
      .container {
        padding: 0px;

        .row {
          margin: 0px;

          .col-lg-9 {
            padding: 0px 10px;
          }
        }
      }
    }
  }

  .stars {
    display: flex;

    // margin-top: 6px;
    button {
      border: none;
      background: none;
      padding-right: 0;

      &:focus {
        outline: none;
      }
    }

    mat-icon {
      color: #FFCF59;
    }
  }

  .test-label {
    font-size: 12px;
    font-family: 'cairo-semibold';
    color: #111934;
    background-color: #FFFFFF;
    border: 1px solid #DEE1EC;
    border-radius: 5px;
    padding: 0 5px 3px;
    margin-right: 6px;

    &.parent {
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background-color: #0774A9;
        color: #FFFFFF;
      }
    }
  }

}

// global classes
.pd-t-lg {
  @media screen and (min-width: $min-lg - 1) {
    padding-top: 60px;
  }
}

ul {
  padding-right: 0px;
}

.border-line {
  background: $light;
  height: 1px;
  width: 100%;
}

.border-full-line {
  background: $light;
  height: 1px;
  width: 108%;
  margin: 10px -26px 26px;

  @media screen and (max-width: $min-lg - 1) {
    width: 104%;
    margin: 10px -12px 26px;
  }

  @media screen and (max-width: $min-sm - 1) {
    width: 106%;
  }

  @media screen and (max-width: $min-xs - 1) {
    width: 109%;
  }
}

.map-container {
  width: 100% !important;
  &:focus {
    outline: none !important;
  }

  .map-form {
    display: none;
  }

  .map-box {
    #map {
      width: 100% !important;
      min-height: 200px !important;
      height: 200px !important;
      border-radius: 10px;
      border: 1px solid #DDDDDD;
    }
  }
}

// layout classes
// page title
.page-title {
  font-size: 18px;
  letter-spacing: -0.18px;
  color: $primary;
  margin-bottom: 10px;
  line-height: 33px;

  @media screen and (max-width: $min-lg - 1) {
    font-size: 16px;
    letter-spacing: -0.16px;
    margin-bottom: 0px;
    line-height: 30px;
  }
}

.text-hint {
  font-size: 13px;
  letter-spacing: -0.13px;
  color: $dark-grey;
  font-family: "cairo-semibold", sans-serif;
  line-height: 24px;
  margin-right: 10px;
}

// banner as cover
.bg-banner {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

// page not exist
.not-found-container {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 18px;
    color: $primary;
    letter-spacing: -0.18px;
    margin-bottom: 10px;
    line-height: 33px;
  }

  p {
    font-family: "cairo-regular", sans-serif;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 26px;
    color: $dark-grey;
  }

  @media screen and (max-width: $min-lg - 1) {
    min-height: 200px;

    h3 {
      font-size: 16px;
      letter-spacing: -0.16px;
      line-height: 30px;
      margin-bottom: 2px;
    }

    p {
      font-size: 13px;
      letter-spacing: -0.13px;
      line-height: 24px;
    }
  }
}

.text-grey {
  font-size: 14px;
  letter-spacing: -0.14px;
  color: $grey;
  line-height: 28px;
  font-family: "cairo-semibold", sans-serif;

  b {
    font-family: "cairo-bold", sans-serif;
  }

  @media screen and (max-width: $min-md - 1px) {
    font-size: 12px;
    letter-spacing: -0.12px;
    line-height: 20px;
  }
}

// list numeric
ol.list-numeric {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-right: 20px;
  margin-bottom: 0px;
  font-family: "cairo-semibold", sans-serif;

  li {
    counter-increment: my-awesome-counter;
    color: $dark-grey;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 28px;
    display: flex;

    &::before {
      content: counter(my-awesome-counter) ". ";
      color: $dark-grey;
      padding-left: 10px;
    }
  }

  @media screen and (max-width: $min-md - 1px) {
    padding-right: 10px;

    li {
      font-size: 13px;
      letter-spacing: -0.13px;
      line-height: 26px;

      &::before {
        padding-left: 6px;
      }
    }
  }
}

// start tooltip style
.tooltip-btn {
  border: 0px;
  background: transparent;
}

.tooltip-inner {
  max-width: 270px;
  text-align: right;
  font-size: 14px;
  letter-spacing: -0.14px;
  font-family: 'cairo-regular', sans-serif;
  color: $white;
  background-color: $light-blue;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba($black, 0.08);

  @media screen and (max-width: $min-md - 1) {
    font-size: 13px;
    letter-spacing: -0.13px;
  }
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-top-color: $light-blue;
}

.tooltip.show {
  opacity: 1 !important;
}

// end tooltip style

// new request button
.new-request {
  margin-bottom: 26px;

  @media screen and (max-width: $min-lg - 1) {
    display: none;
  }
}

// buttons section
.actions-single,
.actions-double,
.actions-triple {
  display: flex;
  justify-content: space-between;

  .btn {
    box-shadow: 0px 2px 2px rgba($black, 0.08);
    min-width: 200px;
  }
}

// one button
.actions-single {
  @media screen and (max-width: $min-md - 1) {
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}

// two buttons
.actions-double {
  .btn {
    @media screen and (max-width: $min-sm - 1) {
      min-width: 145px;
    }
  }
}

// three buttons
.actions-triple {
  @media screen and (max-width: $min-md - 1) {
    flex-wrap: wrap;
  }

  .actions-double {

    .btn {
      &:last-child {
        margin-right: 20px;
      }
    }

    @media screen and (max-width: $min-md - 1) {
      margin-top: 14px;
      width: 100%;

      .btn {
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

// grid for request title and data
.req-body {
  .req-row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $light-grey;
    padding: 26px;
    padding-bottom: 10px;

    .req-box,
    .req-full-box {
      min-width: 50%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 16px;

      .req-title {
        min-width: 50%;
        color: $primary;
        font-size: 14px;
        line-height: 26px;
      }

      .req-data {
        min-width: 50%;
        color: $dark-grey;
        font-size: 14px;
        line-height: 26px;
        font-family: "cairo-regular", sans-serif;
        max-width: 220px;
        padding-left: 14px;
      }

      .req-list {
        padding-right: 18px;
        margin-bottom: 0px;
      }

      .req-actions {
        font-family: "cairo-bold", sans-serif;
        display: flex;

        .btn {
          margin-left: 20px;

          &:last-child {
            margin-left: 0px;
          }
        }
      }
    }

    .req-full-box {
      min-width: 100%;

      .req-title {
        min-width: 25%;
      }

      .req-data {
        min-width: 75%;
        max-width: unset;
        padding-left: 0px;
      }
    }

    @media screen and (max-width: $min-md - 1) {
      padding: 14px 12px;
      padding-bottom: 0px;

      .req-box,
      .req-full-box {
        min-width: 100% !important;
        margin-bottom: 14px;

        .req-title {
          min-width: 50%;
          font-size: 13px;
          line-height: 24px;
        }

        .req-data {
          min-width: 50% !important;
          font-size: 13px;
          line-height: 24px;
          text-align: left;
          max-width: unset;
          padding-left: 0px;
        }

        .req-actions {
          flex-wrap: wrap;

          .btn {
            margin-left: 0px;
            margin-bottom: 14px;

            &:last-child {
              margin-left: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }

      .req-full-box {
        min-width: 100%;
        flex-wrap: wrap;

        .req-title {
          min-width: 100%;
          margin-bottom: 5px;
        }

        .req-data {
          min-width: 100%;
          text-align: right;
        }
      }
    }
  }
}

.inner-page-header {
  height: 60px;
  background-color: #fff;
  position: fixed;
  width: calc(100% - 230px);
  top: 60px;
  z-index: 10;
  padding: 14px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    line-height: 30px;
    font-size: 18px;
    font-family: 'cairo-bold', sans-serif;
  }

}

.confirm-next-step {
  margin-bottom: 67px;

}

.inner-content {
  margin-top: 90px;
  width: 100% !important;

  .main-card {
    margin-top: 0 !important;
  }

  .delete-icon-grid {
    margin-left: 14px !important;
  }

  .delete-icon {
    width: 42px;
    background-color: #FFFFFF;
    margin-left: 14px;
    border-radius: 5px;
    position: relative;

    img {
      position: absolute;
      top: 13px;
      left: 13px;
    }

    button {
      border-color: transparent;
      background-color: #fff;

      &:focus {
        border-color: transparent;
        background-color: #fff;
      }
    }
  }

  #request_table_filter {
    margin-bottom: 0 !important;

    img {
      position: absolute;
      top: 13px;
      right: 10px;
    }

    #search_query {
      width: 100px;
      background-color: #FFFFFF;
      padding-right: 35px;
      padding-left: 16px;
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
      float: right;
      border: none !important;

      &:focus {
        width: 300px;
      }
    }
  }

  .new-element {
    position: absolute;
    left: 50px;
  }
}

.basic-data {
  margin-bottom: 12px;
  padding-right: 20px;

  h4 {
    color: #A0A3AE;
    font-size: 14px;
    font-family: 'cairo-regular', sans-serif;
  }
  a {
    font-size: 14px;
    font-family: 'cairo-semibold', sans-serif;
    margin-bottom: 0 !important;
  }
  p {
    color: #111934;
    font-size: 14px;
    font-family: 'cairo-semibold', sans-serif;
    margin-bottom: 0 !important;

    &.address-map {
      color: #0885C2;
      font-family: 'cairo-regular', sans-serif;
      cursor: pointer;
    }
  }

  .switch-container {
    display: block;

    label {
      display: none;
    }
  }
}

// file input style
.drag-btn {
  width: 100%;
  margin-bottom: 12px;

  img {
    margin-left: 8px;
    margin-right: 5px;
  }

  .add-new-file {
    min-width: 100%;
    height: 42px;
    background-color: #F3F3F5;
    border: 1px solid #CED1D6;
    justify-content: flex-start;

    &.error-file {
      border: 1px solid #D9727F;
    }
  }

  .file-error {
    padding-top: 10px;
    color: #D9727F;
  }
}

.drag-btn.error {
  .add-new-file {
    border-color: #E7A7AF !important;
  }
}

.existance-wrapper {
  display: flex;
  margin-bottom: 12px;

  .existance {
    border: 1px solid #CED1D6;
    border-radius: 5px;
    margin-left: 10px;
    width: calc(100% - 42px);
    vertical-align: middle;
    line-height: 40px;
    padding: 0 16px;
    color: #FFFFFF;
    background-color: #0885C2;

    span {
      font-size: 14px;
      font-family: 'cairo-semibold', sans-serif;
    }

    a {
      text-decoration: none;
      color: #FFFFFF;
      font-size: 14px;
      font-family: 'cairo-semibold', sans-serif;
    }

    img {
      margin-left: 10px;
    }
  }

  .delete-wrapper {
    background-color: #E7E8EB;
    border: 1px solid #CED1D6;
    min-width: 42px;
    width: 42px;
    color: #787F91;

    img {
      margin-left: 0;
    }
  }

  .img-wrapper {
    width: 42px;
    height: 42px;
    margin-left: 10px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }
}

.file-progress {
  border: 1px solid #CED1D6;
  background-color: #E7E8EB;
  padding: 9px 0;
  border-radius: 5px;
  margin-bottom: 12px;

  .file-progress-boxs {
    display: flex;
    width: 100%;
    align-items: center;

    .progress-item {
      &.upload-progress {
        margin-right: 10px;
        width: calc(100% - 22px);

        progress {
          width: 100%;
        }
      }

      .file-name {
        display: block !important;
        font-size: 14px !important;
        font-family: 'cairo-semibold', sans-serif !important;
      }

      #bar {
        width: 100%;
        height: 10px;
      }
    }
  }

}
.save-btn-wrapper {
  display: flex;
  align-items: baseline;
  font-family: 'cairo-semibold';
  span {
    color: #D9727F;   
    margin-right: 10px;
  }
}
.spin-img {
  width: 30px !important;
  height: 30px !important;
  filter: brightness(0) invert(1);
}
.mat-mdc-menu-item-text {
  font-family: 'cairo-regular' !important;
}
.filterCard-comp {
  width: 100%;
}
#security_error h2 {
  font-family: 'cairo-semibold';
  text-align: center;
}
