// tabs panel
ul.nav-tabs {
    border-bottom: 0px;
    border-top-right-radius: 10px;
    // box-shadow: 0px 2px 2px rgba($black, 0.08);
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;

    /* Firefox 64 */
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    .nav-item {
        margin-bottom: 0px;
        margin-left: 8px;
        white-space: nowrap;

        &:last-child {
            margin-left: 0px;
        }

        .nav-link {
            background: $light-grey-2;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            color: $dark-grey;
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 26px;
            padding: 14px 26px;
            border: 0px;

            &.active {
                background: $white;
                color: $primary;
                border: 0px;
            }
        }
    }

    @media screen and (max-width: $min-md - 1) {
        .nav-item {
            margin-left: 8px;

            .nav-link {
                font-size: 13px;
                letter-spacing: -0.13px;
                line-height: 24px;
                padding: 12px;
            }
        }
    }
}
// tabs panel
ul.nav-tabs.horizontal-tab {
   
   border-bottom: 1px solid #DEE1EC;
    // box-shadow: 0px 2px 2px rgba($black, 0.08);
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;

    /* Firefox 64 */
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    .nav-item {
        margin-bottom: 0px;
        margin-left: 8px;
        white-space: nowrap;

        &:last-child {
            margin-left: 0px;
        }

        .nav-link {
            background: transparent;
            padding: 0 0 8px 0;
            margin-left:24px;
            margin-top: 3px;
            font-size: 14px;
            font-family: 'cairo-regular', sans-serif;
            &.active {
                background: $white;
                color: $primary;
               border-bottom: 2px solid $black-primary ;
               font-size: 14px;
               font-family: 'cairo-bold', sans-serif;
            }
        }
    }
    
    @media screen and (max-width: $min-md - 1) {
        .nav-item {
            margin-left: 8px;

            .nav-link {
                font-size: 13px;
                letter-spacing: -0.13px;
                line-height: 24px;
                padding: 12px;
            }
        }
    }
}

.tab-content.horizontal-content {
margin-top: 12px;
}