.damages-layout {

    // damages questions page
    .damage-questions {
        padding-bottom: 0px;

        .form-group {
            margin-bottom: 26px !important;

            @media screen and (max-width: $min-md - 1) {
                margin-bottom: 12px !important;
            }
        }

        .form-label {
            margin-bottom: 16px;

            @media screen and (max-width: $min-md - 1) {
                margin-bottom: 10px !important;
            }
        }

        .col-md-6 {
            @media screen and (max-width: $min-md - 1) {
                padding-right: 0px;
            }
        }
    }

    .damage-details-container {
        .damage-list {
            padding-right: 20px;
            margin-bottom: 0px;
            padding-top: 6px;

            li {
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 26px;
                color: $dark-grey;
                font-family: "cairo-regular", sans-serif;
            }
        }

        // tabs#3 fixing cost button
        .new-damages-container {
            @media screen and (max-width: $min-md - 1) {
                .col-md-6 {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
        }

        // input style as result
        .input-final-result{
            font-size: 14px;
            letter-spacing: -0.14px;
            color: $dark-grey;
            line-height: 42px;

            .total-final-cost{
                margin-left: 6px;
            }

            @media screen and (max-width: $min-md - 1) {
                line-height: 20px;
            }
        }

        .new-damage-item,
        .new-damage-place {
            display: inline-block;
            margin-top: 6px;
        }
    }
}