footer {
    background-color: $primary;
    padding: 80px 0px 20px;
    margin-top: 100px;

    @media screen and (max-width: $min-md - 1) {
        padding: 20px 0px;
        margin-top: 40px;
    }

    // footer title
    h2 {
        color: $light-blue;
        font-size: 15px;
        letter-spacing: -0.15px;
        line-height: 29px;
        margin-bottom: 20px;
        pointer-events: none;

        @media screen and (max-width: $min-md - 1) {
            font-size: 14px;
            letter-spacing: -0.14px;
            pointer-events: all;
            border-bottom: 1px solid rgba($color: #787C88, $alpha: .5);
            padding: 16px;
            margin-bottom: 0;
            line-height: 1.2;

            &::after {
                content: url(../../images/svg/footer-arrow-icon.svg);
                width: 14px;
                height: 7px;
                position: absolute;
                top: 16px;
                left: 26px;
            }

            &.collapsed {
                &::after {
                    transform: rotate(180deg);
                    top: 28px;
                }
            }
        }

        &.payment-title {
            @media screen and (min-width: $min-lg - 1) {
                margin-top: 16px;
            }
        }
    }

    // footer list
    ul {
        list-style: none;
        padding-right: 0px;

        li {
            font-size: 14px;
            font-family: 'cairo-regular', sans-serif;
            letter-spacing: -0.14px;
            padding-bottom: 20px;
            color: $white;

            a {
                color: $white;

                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }

            img {
                height: 28px;

                @media screen and (max-width: $min-md - 1) {
                    height: 24px;
                }
            }

            p {
                line-height: 26px;
                margin-top: -5px;
                margin-bottom: 0px;
                max-width: 240px;

                @media screen and (max-width: $min-lg - 1) {
                    max-width: unset;
                }
            }
        }

        &.payment {
            margin-bottom: 0px;

            @media screen and (max-width: $min-md - 1) {
                border-bottom: 1px solid rgba($color: #787C88, $alpha: .5);
                padding: 16px 0px !important;
                margin: 0px 16px;
            }

            li {
                display: inline-block;
                padding-bottom: 0;
                margin-left: 12px;

                @media screen and (max-width: $min-md - 1) {
                    border-bottom: 0px;
                    padding: 0px;
                }
            }
        }

        @media screen and (max-width: $min-md - 1) {
            padding: 0px 16px !important;

            li {
                font-size: 13px;
                letter-spacing: -0.13px;
                border-bottom: 1px solid rgba($color: #787C88, $alpha: .5);
                padding: 12px;
            }
        }
    }

    .copy-right {
        margin-top: 24px;

        @media screen and (max-width: $min-md - 1) {
            text-align: center;
        }

        p {
            font-size: 14px;
            font-family: 'cairo-regular', sans-serif;
            letter-spacing: -0.14px;
            color: $grey;
            margin-bottom: 0px;

            @media screen and (max-width: $min-md - 1) {
                font-size: 13px;
                letter-spacing: -0.13px;
                line-height: 26px;
            }
        }

        .social-links {
            text-align: left;

            ul {
                margin-bottom: 0px;

                li {
                    display: inline-block;
                    padding-bottom: 0px;
                    padding-right: .7rem;

                    a {
                        img {
                            height: 18px;
                        }
                    }

                    @media screen and (max-width: $min-md - 1) {
                        border-bottom: none;

                        a {
                            img {
                                height: 24px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: $min-md - 1) {
                text-align: center;
                margin-top: 16px;

                ul {
                    li {
                        padding: 0px;
                        padding-left: 16px;
                    }
                }
            }
        }
    }

    // main footer with only copyrights
    &.main-footer {
        padding: 20px 0px;

        .copy-right {
            margin-top: 0px;
        }

        @media screen and (max-width: $min-md - 1) {
            padding: 0px;
            margin-top: 16px;
            visibility: hidden;
        }

        @media screen and (max-width: $min-xs - 1) {
            margin-top: 0px;
        }
    }
}