.new-request-layout , .edit-request-layout{
    .stepper{
        .setup-panel{
            margin: 0px 50px;

            @media screen and (max-width: $min-lg - 1) {
                margin: 4px 9px;
            }
        }
    }

    .setup-body{
        .issue-section{
            padding-bottom: 6px;
        }

        .col-md-6{
            @media screen and (max-width: $min-lg - 1) {
                padding: 0px;
            }
        }

        .setup-input{
            .form-group{
                margin-bottom: 16px !important;
            }

            .has-error{
                input.form-control{
                    border-color: $error;
                }
            }
        }
    }
}