// chrome, opera and safari
.custom-range {
    border: none;

    &:focus {
        &::-webkit-slider-thumb {
            box-shadow: none;
        }
    }

    &::-webkit-slider-runnable-track {
        height: 8px !important;
        background: url("../../images/svg/range.svg");
        background-size: calc(100% - 16px);
        background-color: $white !important;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0px !important;
        -webkit-appearance: none;

        @media screen and (max-width: $min-md - 1) {
            background-size: calc(100% - 8px);
        }
    }

    &::-webkit-slider-thumb {
        width: 10px !important;
        height: 20px !important;
        margin-right: -2px;
        margin-top: -8px !important;
        background-color: $white !important;
        box-shadow: 0px 2px 2px rgba($black, 0.08);
        border: 1px solid $light-grey-2 !important;
        border-radius: 0px !important;
        -webkit-appearance: none;
        appearance: none;

        &::after{
            contain: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 6px;
            height: 6px;
            background: blue;

        }

        &:active {
            background: $white;
        }
    }
}

// numbers above input track
.range-numbers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;

    @media screen and (max-width: $min-md - 1) {
        width: 98%;
        margin: auto;
        margin-bottom: 8px;
    }

    span {
        padding: 0px;
        width: 14px;
        line-height: 1;
        color: $dark-grey;
        font-family: 'cairo-regular', sans-serif;
        font-size: 14px;
        letter-spacing: -0.14px;
    }
}

input[type=range]::-moz-range-track {
    height: 8px !important;
    background: url("../../images/svg/range.svg");
    background-size: calc(100% - 16px);
    background-color: $white !important;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px !important;

    @media screen and (max-width: $min-md - 1) {
        background-size: calc(100% - 8px);
    }
}

input[type=range]::-moz-range-thumb {
    width: 10px !important;
    height: 20px !important;
    margin-right: -2px;
    margin-top: -8px !important;
    background-color: $white !important;
    box-shadow: 0px 2px 2px rgba($black, 0.08);
    border: 1px solid $light-grey-2 !important;
    border-radius: 0px !important;

    &:active {
        background: $white;
    }
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring {
    outline: none !important;
    outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
    height: 8px !important;
    background: url("../../images/svg/range.svg");
    background-size: calc(100% - 16px);
    background-color: $white !important;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px !important;
}

// IE +10
input[type=range] {
    /* fix for FF unable to apply focus style bug  */
    border: 1px solid $white;

    /*required for proper track sizing in FF*/
    width: 100%;
    &::-ms-track {
        height: 8px !important;
        background: url("../../images/svg/range.svg");
        background-size: calc(100% - 16px);
        background-color: $white !important;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0px !important;

        @media screen and (max-width: $min-md - 1) {
            background-size: calc(100% - 8px);
        }
    }

    &::-ms-fill-lower {
        background: transparent;
    }

    &::-ms-fill-upper {
        background: transparent;
    }

    &::-ms-thumb {
        width: 10px !important;
        height: 20px !important;
        margin-right: -2px;
        margin-top: -8px !important;
        background-color: $white !important;
        box-shadow: 0px 2px 2px rgba($black, 0.08);
        border: 1px solid $light-grey-2 !important;
        border-radius: 0px !important;

        &:active {
            background: $white;
        }
    }

    &:focus::-ms-fill-lower {
        background: transparent;
    }

    &:focus::-ms-fill-upper {
        background: transparent;
    }
}