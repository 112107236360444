.breadcrumb-custom{
    margin-bottom: 26px;
    display: flex;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;

    /* Firefox 64 */
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    .breadcrumb-list{
        list-style: none;
        padding-left: 12px;

        .breadcrumb-link{
            text-decoration: none;

            img{
                padding-right: 12px;
                margin-right: -4px;
                height: 14px;
            }
        }

        .breadcrumb-item{
            display: inline-flex;
            color: $dark-grey;
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 26px;
            font-family: 'cairo-regular', sans-serif;
        }
    }

    @media screen and (max-width: $min-lg - 1px) {
        margin-bottom: 12px;

        .breadcrumb-list{
            padding-left: 10px;

            .breadcrumb-link{
                img{
                    padding-right: 10px;
                }
            }

            .breadcrumb-item{
                font-size: 13px;
                letter-spacing: -0.13px;
            }
        }
    }
}