// change container max-width
body {
    .container {
        @media screen and (min-width: $min-xl) {
            max-width: 1200px !important;
        }

        @media screen and (min-width: $min-lg) {
            max-width: 100%;
        }

        @media screen and (max-width: $min-lg) {
            max-width: 100%;
        }

        @media screen and (max-width: $min-md - 1) {
            max-width: 100%;
        }

        @media screen and (max-width: $min-sm - 1) {
            max-width: 100%;
        }
    }

    // margin
    .m-4 {
        margin: 26px !important;
    }

    .mt-4 {
        margin-top: 26px !important;
    }

    .mb-4 {
        margin-bottom: 26px !important;
    }

    .mt-lg-4 {
        @media screen and (min-width: $min-md - 1) {
            margin-top: 26px !important;
        }
    }

    .mb-lg-4 {
        @media screen and (min-width: $min-md - 1) {
            margin-bottom: 26px !important;
        }
    }

    // padding
    .p-4 {
        padding: 26px !important;
    }

    .pt-4 {
        padding-top: 26px !important;
    }

    .pb-4 {
        padding-bottom: 26px !important;
    }
}