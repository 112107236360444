.pagination-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: flex-start;
  margin: 20px 35px 95px;

  .total-count {
    span {
      margin-left: 5px;
      font-family: "cairo-semibold", sans-serif;
      font-size: 16px;
    }
  }
}
.pageSize .ng-select.ng-select-opened>.ng-select-container,
.pageSize  .ng-select .ng-select-container {
  background-color: $white !important;
}
// .paginantion-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .ng-dropdown-panel.ng-select-top {
//   background-color: #fff !important;
// }

.pageSize .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.pageSize .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #fff !important;

}
.my-pagination {

  ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;

    li {
      a {
        height: 42px;
        width: 42px;
        float: right;
        padding: 0;
        color: #707585;
        background-color: #fff;
        font-family: 'cairo-regular', sans-serif;
        font-size: 14px;
        border-right: 1px solid #f3f3f5;
        border-left: 1px solid #f3f3f5;
        border-top: 0;
        border-bottom: 0;
        margin-right: -1px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;

        @media screen and (max-width: 480px) {
          height: 35px;
          width: 35px;
          margin-right: 0;

        }

        span {
          margin: 0 auto;
        }

        &:hover {
          background: #f3f3f5;
        }
      }
    }

    .current {
      height: 42px;
      width: 42px;
      float: right;
      padding: 0;
      font-family: 'cairo-regular', sans-serif;
      font-size: 14px;
      border-right: 1px solid #f3f3f5;
      border-left: 1px solid #f3f3f5;
      border-top: 0;
      border-bottom: 0;
      margin-right: -1px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      background: #111934;
      color: #fff;

      @media screen and (max-width: 480px) {
        height: 35px;
        width: 35px;
        margin-right: 0;

      }

      span {
        margin: 0 auto;
      }
    }

    .pagination-previous {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      a {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 42px;
        width: 70px;
        float: right;
        padding: 0;
        color: #111934;
        background-color: #fff;
        font-family: 'cairo-regular', sans-serif;
        font-size: 14px;
        border-right: 1px solid #f3f3f5;
        border-left: 1px solid #f3f3f5;
        border-top: 0;
        border-bottom: 0;
        margin-right: -1px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;

        @media screen and (max-width: 480px) {
          height: 35px;
          width: 35px;
          margin-right: 0;

        }

        &:before {
          margin: 0 auto !important;
          content: 'السابق'
        }
      }

      &.disabled {
        &:before {
          margin: 0 auto !important;
          content: 'السابق'
        }
      }

    }

    .disabled {
      height: 42px;
      width: 70px;
      float: right;
      padding: 0;
      color: #111934;
      background: #FFFFFF;
      font-family: 'cairo-regular', sans-serif;
      font-size: 14px;
      border-top: 0;
      border-bottom: 0;
      margin-right: -1px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;

      @media screen and (max-width: 480px) {
        height: 35px;
        width: 35px;
        margin-right: 0;

      }
    }

    .pagination-next {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      a {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 42px;
        width: 70px;
        float: right;
        padding: 0;
        color: #111934;
        background-color: #fff;
        font-family: 'cairo-regular', sans-serif;
        font-size: 14px;
        border-right: 1px solid #f3f3f5;
        border-left: 1px solid #f3f3f5;
        border-top: 0;
        border-bottom: 0;
        margin-right: -1px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;

        @media screen and (max-width: 480px) {
          height: 35px;
          width: 35px;
          margin-right: 0;

        }

        &:after {
          margin: 0 auto !important;
          content: 'التالي'
        }

        &:focus {
          outline: none;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &.disabled {
        &:before {
          margin: 0 auto !important;
          content: 'التالي'
        }

        &:after {
          content: ''
        }
      }
    }
  }
}

.custom-paginate {
  margin-top: 30px;

  @media screen and (max-width: $min-md - 1) {
    margin-top: 16px;
  }

  .dataTables_paginate {
    margin-top: 0px;

    .pagination {
      margin-bottom: 0px;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 2px 2px rgba($black, 0.08);

      .paginate_button {
        a {
          height: 42px;
          width: 42px;
          float: right;
          padding: 0px;
          color: $dark-grey;
          background-color: $white;
          font-family: "cairo-regular", sans-serif;
          font-size: 14px;
          border-right: 1px solid $light;
          border-left: 1px solid $light;
          border-top: 0px;
          border-bottom: 0px;
          margin-right: -1px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus {
            outline: none;
          }

          &:hover {
            text-decoration: none
          }
        }

        &.first,
        &.last {
          a {
            background: $light-grey-2;
          }

          &:hover {
            a {
              background: $light-grey-2;
            }
          }
        }

        &:hover {
          a {
            background: $light;
          }
        }

        &.active {
          a {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }
}

.ngx-pagination .disabled:hover {
  background-color: $grey-7 !important;
}
