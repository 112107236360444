.stepper {
    margin-bottom: 20px;
    .main-card{
        border-radius: 5px;
        margin-bottom: 0;
    }
    .setup-panel {
        display: flex;
        justify-content: space-between;
        position: relative;
        &:before {            
            content: '';
            height: 4px;
            background: $light;
            position: absolute;
            top: 30px;
            right: 40px;
            left: 40px;
            z-index: 0;

            @media screen and (max-width: $min-lg - 1) {
                top: 8px;
            }
        }

        .step-action {
            text-align: center;

            .step-status {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                pointer-events: none;
                .step-circle {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 20px;
                    color: #111934;
                    font-family: 'cairo-bold', sans-serif;
                    background: $dark-grey-2;
                    border-radius: 50%;
                    z-index: 1;
                    img{
                        display: none;
                    }
                }
                .step-title {
                    font-size: 15px;
                    font-family: "cairo-semibold", sans-serif;
                    color: #111934;
                    letter-spacing: -0.14px;
                    line-height: 26px;
                    margin-bottom: 0px;
                    margin-top: 10px;
                }
                &.step-active {
                    .step-circle {
                        background: $light-green; 
                        color: #fff !important;
                    }
                    .step-title{
                        color: $primary;
                        font-size: 15px;
                        font-family: "cairo-bold", sans-serif;
                    }
                }
            }
        }
    }
}