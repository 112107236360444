.notification-layout{
    .notification-container{
        padding: 10px 0px; 
        margin-bottom: 10px;

        @media screen and (max-width: $min-lg - 1) {
            padding: 0px;
        }

        .notification-item{
            border-bottom: 1px solid $light-grey;
            padding: 16px 0px; 

            &:last-child{
                border-bottom: 0px;
            }

            @media screen and (max-width: $min-md - 1) {
                padding: 10px 0px; 
            }

            .notification-content{
                font-size: 14px;
                letter-spacing: -0.14px;
                color: $dark-grey;
                font-family: "cairo-regular", sans-serif;
                padding: 0px 26px;
                margin-bottom: 8px;
                line-height: 26px;

                @media screen and (max-width: $min-md - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    padding: 0px 10px;
                    margin-bottom: 4px;
                }
            }

            .notification-date{
                padding: 0px 26px;

                @media screen and (max-width: $min-md - 1) {
                    padding: 0px 10px;
                }

                span{
                    font-family: "cairo-semibold", sans-serif;
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    color: $grey;
                }
            }
        }
    }
}