.table-responsive {

    // table scroll
    &::-webkit-scrollbar {
        height: 8px;
        border-radius: 5px;
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: $light;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $grey;
        border-radius: 5px;
    }

    &.two-sm-table {
        overflow-x: hidden;

        @media screen and (max-width: $min-md - 1) {
            overflow-x: auto;

            .col-lg-6 {
                padding-right: 10px;
            }
        }
    }
}

table.table {
    margin-bottom: 0px;

    thead {
        tr {
            th {
                font-size: 14px;
                letter-spacing: -0.14px;
                color: $primary;
                padding: 16px;
                background: $light;
                border: 0px;
                vertical-align: middle;

                @media screen and (max-width: $min-md - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    padding: 14px 12px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px;
                letter-spacing: -0.14px;
                color: $dark-grey;
                font-family: "cairo-regular", sans-serif;
                border-top: 1px solid $light;
                margin-top: -1px;
                padding: 14px 16px;
                vertical-align: middle;

                @media screen and (max-width: $min-md - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    padding: 8px 12px;
                    min-width: 120px;
                }

                &.table-row-lg {
                    @media screen and (max-width: $min-md - 1) {
                        min-width: 170px;
                    }
                }

                // custom width for table column
                &.wd-xxs {
                    min-width: 40px;
                    width: 40px;
                }

                &.wd-xs {
                    min-width: 80px;
                    width: 80px;
                }

                &.wd-sm {
                    min-width: 130px;
                    width: 130px;
                }

                &.wd-md {
                    min-width: 200px;
                    width: 200px;
                }

                &.wd-lg {
                    min-width: 300px;
                    width: 300px;
                }

                &.wd-xl {
                    min-width: 400px;
                    width: 400px;
                }
            }
        }
    }

    &.table-hover {
        tbody {
            tr {
                cursor: pointer;

                &:hover {
                    background: $light-grey-hover;
                }
            }
        }
    }

    // table with border only as in report page
    &.table-bordered {
        border: 0px;
        border-right: 0px;

        thead {
            tr {
                th {
                    background: transparent;
                    padding: 16px 14px;

                    // remove padding from first col
                    &.f-col {
                        padding-right: 0px !important;
                        padding-left: 0px !important;
                    }
                }
            }
        }

        tbody {
            border-bottom: 2px solid $border-grey;

            tr {
                td {
                    border-top: 2px solid $border-grey;
                    border-left: 2px solid $border-grey;
                    padding: 16px 14px;
                    line-height: 26px;
                    border-right: 0px;

                    &:last-child {
                        border-left: 0px;
                    }

                    .table-list {
                        margin-bottom: 0px;
                        padding-right: 20px;
                    }

                    // remove padding from first col
                    &.f-col {
                        padding-right: 0px !important;
                        padding-left: 0px !important;
                    }

                    // change text color to primary
                    &.main-t-title {
                        color: $primary;
                        font-family: "cairo-semibold", sans-serif;
                    }

                    // last row in table with result
                    &.row-result {
                        font-family: "cairo-bold", sans-serif;
                        color: $primary;
                    }
                }
            }
        }

        // for questions table
        &.question-table {
            tbody {
                tr {
                    td {
                        border-right: 0px;

                        // put min width for question
                        &.f-col {
                            @media screen and (max-width: $min-lg - 1) {
                                min-width: 300px;
                                width: 80%;
                            }
                        }
                    }
                }
            }

            // remove table head to be one table in mobile view
            &.mob-no-head {
                @media screen and (max-width: $min-lg - 1) {
                    margin-top: -2px;

                    thead {
                        display: none;
                    }
                }
            }
        }
    }
}

#order-table {
    overflow-x: auto;
    white-space: nowrap;
    border-radius: 4px;
    border-spacing: 0px;
    border-collapse: separate;

    th {
        border-top: none;
        color: #111934;
        font-family: 'cairo-bold', sans-serif;
        font-size: 14px;
        border-left: 1px solid #DEE1EC;
        border-bottom: 1px solid #DEE1EC;

        &.width-13 {
            width: 13%;
        }

        &.width-12 {
            width: 12%;
        }

        &.width-10 {
            width: 10%;
        }

        &.width-15 {
            width: 15%;
        }

        &.width-20 {
            width: 20%;
        }

        &.width-25 {
            width: 25%;
        }

        &.width-30 {
            width: 30%;
        }

        &:first-child {
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-top-left-radius: 4px;
            border-left: none;
        }
    }

    tbody {
        color: #787F91;

        tr {
            cursor: pointer;
            outline: none;

            &:nth-of-type(odd) {
                background-color: #FCFCFC;
            }

            &:nth-of-type(even) {
                background-color: #F3F3F5;
            }

            &:last-of-type td {
                border-bottom: none;
            }

            &:last-of-type {
                td:first-child {
                    border-bottom-right-radius: 4px;
                }

                td:last-child {
                    border-bottom-left-radius: 4px;
                }
            }

            th {
                border-top: none;
                color: #111934;
                font-family: 'cairo-bold', sans-serif;
                font-size: 14px;
                border-left: 1px solid #DEE1EC;
                border-bottom: 1px solid #DEE1EC;

                &.width-13 {
                    width: 13%;
                }

                &.width-12 {
                    width: 12%;
                }

                &.width-10 {
                    width: 10%;
                }

                &.width-15 {
                    width: 15%;
                }

                &:first-child {
                    border-top-right-radius: 4px;
                }

                &:last-child {
                    border-top-left-radius: 4px;
                    border-left: none;
                }
            }

            td {
                padding: 10px;
                max-width: 190px;
                white-space: normal;
                border-left: 1px solid #DEE1EC;
                border-bottom: 1px solid #DEE1EC;
                border-top: none;

                &:last-child {
                    border-left: none;
                }

                &.assign-status {
                    width: 190px;
                    min-width: 190px;
                    white-space: normal;
                }

                button {
                    &.not-finished {
                        background-color: $grey-6;
                        color: $grey-3;
                        border: 1px solid $grey-3;
                    }

                    &.new {
                        background-color: $background-blue;
                        color: $light-blue;
                        border: 1px solid #0885C2;
                    }

                    &.inspectoring {
                        background-color: #FFFDF7;
                        color: $text-yellow;
                        border: 1px solid #FFC545;
                    }

                    &.audit {
                        background-color: #FFFDF7;
                        color: $text-yellow;
                        border: 1px solid #FFC545;
                    }

                    &.processing {
                        background-color: #FFFDF7;
                        color: $text-yellow;
                        border: 1px solid #FFC545;
                    }

                    &.scheduled {
                        background-color: #FFFDF7;
                        color: $text-yellow;
                        border: 1px solid #FFC545;
                    }

                    &.delivered {
                        background-color: #FFFDF7;
                        color: $text-yellow;
                        border: 1px solid #FFC545;
                    }

                    &.completed {
                        background-color: $background-green;
                        color: $success;
                        border: 1px solid $success;
                    }

                    &.canceled {
                        background-color: $background-red;
                        color: $error;
                        border: 1px solid #D9727F;
                    }
                }
            }
        }
    }
}
