@import "../components/var";
.sidenav{
    background: $white;
    padding: 20px 0px;
    z-index: 100;
    box-shadow: 0px 2px 2px rgba($black, 0.08);
    height: calc(100% - 60px);
    border-radius: 0;
    background-color: $black-primary;
    position: fixed;
    width:250px;
    top: 60px;
    overflow: auto;
    .sidenav-title {
        padding: 17px 32px 20px 0;
        margin-bottom: 0;
        color: $grey-3;
        font-size: 14px;
        font-family: 'cairo-bold', sans-serif;
    }
    .nav{
        position: relative;
        padding-right: 0px;        
        .nav-item{
            border-right: 0;
            margin: 0 10px;
            .nav-link{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 14px 22px;
                img{
                    width: 20px;
                    height: 20px;
                }
                span{
                    color: $white;
                    display: inline-block;
                    font-size: 14px;
                    font-family: 'cairo-regular', sans-serif;
                    letter-spacing: -0.14px;
                    margin: 0px;
                    padding: 0px 14px;
                    line-height: 26px;
                }
                .order-count {
                    margin-right: auto;
                    border-radius: 16px;
                    background-color: $green-dark;
                    padding: 0 10px;
                    height: 18px;
                    font-size: 11px;
                    line-height: 18px;
                }
            }
            
            &:hover{
                border-color: none;
                background-color: $grey-2 ;
                border-radius:4px ;
                cursor: pointer;
                span {
                font-family: 'cairo-semibold', sans-serif !important;
                }
                .icon-dashboard-icon:before {
                color : $grey-4 ;
                content: "\e901";
                }
                .icon-client-icon:before {
                    color : $grey-4 ;
                    content: "\e903";
                }
                .icon-engineer-icon:before {
                    color : $grey-4 ;
                    content: "\e902";
                }
                .icon-coupon-icon:before {
                    color : $grey-4 ;
                    content: "\e900";
                }
                .icon-order-icon:before {
                    color : $grey-4 ;
                    content: "\e904";
                }
                .nav-icon {
                    img {
                        filter: brightness(0) invert(1)
                    }
                }
            }

            &.active{
                background-color: $grey-2 !important;
                border-radius:4px ;
                span {
                font-family: 'cairo-semibold', sans-serif !important;
                }
                .icon-dashboard-icon:before {
                    color : $grey-4 ;
                    content: "\e901";
                }
                .icon-client-icon:before {
                    color : $grey-4 ;
                    content: "\e903";
                }
                .icon-engineer-icon:before {
                    color : $grey-4 ;
                    content: "\e902";
                }
                .icon-coupon-icon:before {
                    color : $grey-4 ;
                    content: "\e900";
                }
                .icon-order-icon:before {
                    color : $grey-4 ;
                    content: "\e904";
                }
                .nav-icon {
                    img {
                        filter: brightness(0) invert(1)
                    }
                }
            }
            &.logout-btn {
                position: fixed;
                top: calc(100% - 54px);
                height: 54px;
                right: 0px;
                width: 235px;
                margin: 0 !important;
                padding: 0 10px;
                background: $grey-2;
                &:hover{
                  border-radius: 0 !important;
                }
            }
        }
        .sub-menu{
            // padding-right: 5px;
            .sub-items{
              padding-right: 20px;
             &.active {
               background-color: $grey-2 !important;
               border-radius:4px ;
               span{
                font-family: 'cairo-semibold', sans-serif !important;
               }
             }
             span {
               font-family: 'cairo-regular', sans-serif !important;
             }
             &:hover{
               border-color: none;
               background-color: $grey-2 !important ;
               border-radius:4px ;
               span {
                font-family: 'cairo-semibold', sans-serif !important;
              }
             }
           }
           .sub-link
            {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 14px 22px;
                cursor: pointer;
                margin: 0 10px;
                span{
                    color: $white;
                    display: inline-block;
                    font-size: 14px;
                    font-family: 'cairo-regular', sans-serif !important;
                    letter-spacing: -0.14px;
                    margin: 0px;
                    padding: 0px 14px;
                    line-height: 26px;
                }
                &:hover{
                    .nav-icon {
                        img {
                            filter: brightness(0) invert(1)
                        }
                    }
                }
            }
        }
    }
    &::-webkit-scrollbar-track{
        background-color:$white;
        border-radius:0;
    }
    &::-webkit-scrollbar{
        width:6px;
        border-radius:0;
        left:20px;
        top: 100px;
    }
    &::-webkit-scrollbar-thumb{
        background-color:$grey-2;
        border-radius:0;
    }

    @media screen and (max-width: $min-lg - 1) {
        border-radius: 0px;
        padding: 0px;
        margin-bottom: 0px;
        width: 100%;
        position: fixed;
        right: 0px;
        bottom: 0px;
        box-shadow: 0px 2px 2px rgba($black, 0.08);

        .nav{
            flex-direction: row !important;
            flex-wrap: nowrap;

            .nav-item{
                border-bottom: 5px solid transparent;
                border-right: 0px;
                min-width: 20%;
                height: 64px;
                display: flex;
                justify-content: center;
                
                .nav-link{
                    padding: 0px;

                    img{
                        margin: auto;
                    }

                    span{
                        display: none;
                    }
                }
            }
        }
    }
}
