// request list style
.request-list {
    padding: 0px;

    .nav-item {
        border: 1px solid $light-grey-2;
        background: $lighter-grey-2;
        width: 100%;
        border-radius: 10px;
        margin-bottom: 16px;

        &:last-child{
            margin-bottom: 0px;
        }

        @media screen and (max-width: $min-md - 1px) {
            margin-bottom: 12px;
        }

        &.request-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;
            padding: 14px 20px;
            height: 54px;

            @media screen and (max-width: $min-md - 1px) {
                padding: 11px 12px;
                height: 46px;
            }

            // title
            .request-title {
                min-width: 80%;
                color: $primary;
                margin: 0px;
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 24px;
                padding: 0px;

                @media screen and (max-width: $min-md - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    min-width: 70%;
                }
            }

            .request-action {
                min-width: auto;
                text-align: end;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                // delete
                .delete-action {
                    margin: 0px;
                    padding: 0px;
                    border: 0px;
                    background: transparent;

                    @media screen and (min-width: $min-lg - 1px) {
                        margin-right: 14px;
                    }

                    &:focus {
                        outline: none;
                    }

                    img {
                        width: 34px;
                        height: 34px;
                        margin: 0px;
                    }
                }

                // success
                .succes-action {
                    border: 0px;
                    background: transparent;
                    display: none;
                    width: 22px;
                    height: 22px;

                    @media screen and (max-width: $min-lg - 1px) {
                        width: 18px;
                        height: 18px;
                        margin-left: 10px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                // arrow "<"
                .card-arrow {
                    width: 22px;
                    height: 22px;
                    margin-right: 20px;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    @media screen and (max-width: $min-lg - 1px) {
                        margin-right: 10px;
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            // when hover on list item, delete and success icon display flex
            // on desktop view
            &:hover {
                @media screen and (min-width: $min-lg - 1px) {
                    .request-action {
                        .dropdown-mob-item {
                            .dropdown {

                                .dropdown-toggle {
                                    img {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }

                                .dropdown-menu {
                                    display: contents;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// dropdown show in mob view in inspection requests pages
.dropdown-mob-item {
    .dropdown {
        .dropdown-toggle {
            display: none;
        }

        .dropdown-menu {
            position: relative;
            background: transparent;
            box-shadow: none;
            border: 0px;

            &.show {
                min-width: 70px;
                justify-content: center;
                display: flex;
                padding: 12px 6px;
                box-shadow: 0px 2px 8px rgba($black, 0.08);
                position: absolute;
                top: 16px !important;
                left: -22px !important;
                border: 1px solid $light-grey-2;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                }

                &::before {
                    left: 20px;
                    border: 11px solid transparent;
                    border-bottom-color: $light-grey-2;
                }

                &::after {
                    left: 21px;
                    border: 10px solid transparent;
                    border-bottom-color: $white;
                }

                button {
                    img {
                        width: 34px;
                        height: 34px;
                        border-radius: 0px;
                    }

                    .edit-btn {
                        border: 1px solid $light-blue;
                        border-radius: 10px;
                    }
                }
            }

            button {
                border: 0px;
                background: transparent;
            }
        }
    }

    @media screen and (max-width: $min-lg - 1px) {
        .dropdown {
            .dropdown-toggle {
                padding: 0px;
                border: 0px;
                display: flex;
                background: transparent;

                &::after {
                    display: none;
                }

                img {
                    margin: 0px;
                    width: 18px;
                }

                &:focus {
                    outline: 0 !important;
                }
            }

            .dropdown-menu {
                border-radius: 10px;
                border: 1px solid rgba($color: $black, $alpha: .1);
                box-shadow: 0px 2px 2px #00000014;
                text-align: center;
                min-width: 80px;
                margin: 0px;
                padding: 0.7rem;
                background: $white;
                top: 6px !important;
                left: -1.4rem !important;

                .delete-action {
                    background: transparent;
                }
            }
        }
    }
}