$primary: #111934;
$secondary: #FFC545;
//////////start refactor//////////////////////////////////
//primary
$yellow-dark : #FFC545;
$yellow-primary : #FFCF59;
$yellow-light-1 : #FFD97A;
$yellow-light-2 : #FFE29B;
$yellow-light-3 : #FFECBD;
$yellow-light-4 : #FFF5DE;
$yellow-light-5 : #FFFDF7;
$white : #FFFFFF;
//secondary
$black-primary : #111934;
$grey-1 : #1B2348;
$grey-2 : #585E71;
$grey-3 : #707585;
$grey-4 : #A0A3AE;
$grey-5 : #CED1D6;
$grey-6 : #E7E8EB;
$grey-7 : #F3F3F5;
$grey-8 : #DEE1EC;
$white-secondary : #FAFAFB;
//success
$green-dark : #379B79;
$green-primary : #40B58D;
$green-light-1 : #5CBA85;
$green-light-2 : #87C379;
$green-light-3 : #B9CC6B;
$green-light-4 : #E3D05E;
$green-bg:#CAE4DB;
//error 
$red-dark : #C06570;
$red-primary : #D9727F;
$red-light-1 : #E18179;
$red-light-2 : #F0976E;
$red-light-3 : #F3E0E3;
$orange-primary : #FEAE63;
$orange-light-1 : #FFC45C;
//assistant
$blue-dark : #0774A9;
$blue-primary : #0885C2;
$blue-light-1 : #EEFAFF;
$blue-light-2 : #DAE9F1;
////////////end refactor/////////////////////////

$light: #F3F3F5;
$lighter: #F5F5F5;
$light-grey: #EEEEEE;
$light-grey-2: #E7E8EB;
$lighter-grey: #E6E7EA;
$lighter-grey-2: #FAFAFB;
$dark-grey: #707585;
$dark-grey-2: #BFBFBF;
$grey: #A0A3AE;
$grey-9: #CFD1D6;
$background-grey: #E3E4EA;
$text-grey:#969DB3;
$background-blue:#BFDEEE;
$background-yellow:#FDF1D3;
$background-red:#F4D9DD;
$background-green:#CAE4DB;

$text-yellow:#FEB503;
$black: #000000;
$white: #ffffff;

$error: #D9727F;
$success: #379B79;
$light-green: #40B58D;
$lighter-green: #BCCB77;
$orange: #FEAE63;
$yellow: #FFCF59;
$light-blue: #0885C2;
$info: #585E71;

// custom colors for specific pages
$border-grey: #E3E3E3;
$line-grey: #C8CCDB;
$progress-bg: #DEDEDE;
$scroll-track: #F2F3F4;
$box-border: #B4B6BF;
$primary-hover: #0D1226;
$grey-hover: #C5C7CC;
$light-grey-hover: #F9F9FB;
$yellow-hover: #FFC545;
$success-hover: #338F6F;
$error-hover: #CC6B77;

$border-style: 1px solid #DEE1EC;

// gird
$min-xs: 375px;
$min-sm: 576px;
$min-md: 768px;
$min-lg: 992px;
$min-xl: 1200px;

// override bootstrap colors
$theme-colors: ("primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "lighter": $lighter,
  "light-grey": $light-grey,
  "lighter-grey": $lighter-grey,
  "dark-grey": $dark-grey,
  "grey": $grey,
  "grey-2": $grey-2,
  "error": $error,
  "success": $success,
  "yellow": $yellow,
  "orange": $orange,
  "light-blue": $light-blue,
  "lighter-green": $lighter-green,
  "info": $info);
