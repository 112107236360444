/*****************************************
********* Fonts Start
*****************************************/

// cairo regular
@font-face {
    font-family: 'cairo-regular';
    src: url('../../fonts/Cairo-Regular.eot');
    src: url('../../fonts/Cairo-Regular.woff') format('woff'),
         url('../../fonts/Cairo-Regular.ttf') format('truetype'),
         url('../../fonts/Cairo-Regular.svg') format('svg');
    font-weight: normal; 
    font-style: normal;
}

// cairo semi bold
@font-face {
    font-family: 'cairo-semibold';
    src: url('../../fonts/Cairo-SemiBold.eot');
    src: url('../../fonts/Cairo-SemiBold.woff') format('woff'),
         url('../../fonts/Cairo-SemiBold.ttf') format('truetype'),
         url('../../fonts/Cairo-SemiBold.svg') format('svg');
    font-weight: normal; 
    font-style: normal;
}

// // cairo bold
@font-face {
    font-family: 'cairo-bold';
    src: url('../../fonts/Cairo-Bold.eot');
    src: url('../../fonts/Cairo-Bold.woff') format('woff'),
         url('../../fonts/Cairo-Bold.ttf') format('truetype'),
         url('../../fonts/Cairo-Bold.svg') format('svg');
    font-weight: normal; 
    font-style: normal;
}

.font-16{
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 30px;

    @media screen and (max-width: $min-md - 1px) {
        font-size: 14px;
        letter-spacing: -0.14px;
        line-height: 20px;
    }
}

.font-17{
    font-size: 17px;
    letter-spacing: -0.17px;
    line-height: 32px;

    @media screen and (max-width: $min-md - 1px) {
        font-size: 15px;
        letter-spacing: -0.15px;
        line-height: 20px;
    }
}

// // font family
.font-semibold{
    font-family: "cairo-semibold", sans-serif;
}

/*****************************************
********* Fonts End
*****************************************/