.home-layout {
    .page-title {
        margin-bottom: 20px;

        @media screen and (max-width: $min-lg - 1) {
            margin-bottom: 12px;
        }
    }

    .main-card {
        &.home-table {
            padding: 0px;

            @media screen and (max-width: $min-md - 1) {
                margin-top: 2px;
            }

            .page-title {
                padding: 26px;
                margin: 0px;

                @media screen and (max-width: $min-lg - 1) {
                    padding: 12px;
                }
            }
        }
    }

    .col-md-4{
        @media screen and (max-width: $min-md - 1) {
            padding: 0px;
        }
    }
}