.login-layout{
    .login-card{
        background: $white;
        border-radius: 10px;
        padding: 20px 30px;
        box-shadow: 0px 2px 2px rgba($black, 0.08);

        @media screen and (max-width: $min-lg - 1) {
            padding: 16px 12px;
        }

        .login-title{
            color: $primary;
            font-size: 22px;
            letter-spacing: -0.22px;
            line-height: 42px; 
            margin-bottom: 30px;

            @media screen and (max-width: $min-lg - 1) {
                font-size: 16px;
                letter-spacing: -0.16px;
                line-height: 30px;
                margin-bottom: 16px;
            }
        }

        .login-action{
            color: $light-blue;
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 26px;
            text-decoration: none;

            @media screen and (max-width: $min-lg - 1) {
                font-size: 13px;
                letter-spacing: -0.13px;
                line-height: 24px;
            }
        }

        .login-note{
            margin-bottom: 0px;

            .go-back{
                font-size: 14px;
                letter-spacing: -0.14px;
                color: $primary;
                line-height: 26px;
                margin-left: 5px;

                @media screen and (max-width: $min-lg - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                    line-height: 24px;
                    color: $dark-grey;
                    font-family: "cairo-semibold", sans-serif;
                    margin-left: 6px;
                }
            }
        }

        .login-hint{
            font-size: 14px;
            letter-spacing: -0.14px;
            color: $dark-grey;
            font-family: "cairo-regular", sans-serif;
            line-height: 26px;
            margin: -20px 0px 30px;

            @media screen and (max-width: $min-lg - 1) {
                font-size: 13px;
                letter-spacing: -0.13px;
                margin: -16px 0px 16px;
            }
        }

        // buttons
        .login-btn{
            padding: 20px 0px 20px;

            @media screen and (max-width: $min-lg - 1) {
                padding: 6px 0px 16px;
            }
        }
        .send-btn{
            padding: 10px 0px 30px;

            @media screen and (max-width: $min-lg - 1) {
                padding: 10px 0px 16px;
            }            
        }

        .restore-password-btn{
            padding: 10px 0px 0px;
            .btn{
                background-color: $secondary;
            }
           
        }
    }
    
    .alert-box{
        margin-bottom: 30px;

        @media screen and (max-width: $min-lg - 1) {
            margin-bottom: 16px;
        }
    }
}