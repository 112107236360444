.tooltip-inner {
    max-width: 270px;
    text-align: right;
    font-size: 14px;
    letter-spacing: -0.14px;
    font-family: 'cairo-regular', sans-serif;
    color: $white;
    background-color: $light-blue;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba($color: $black, $alpha: 0.08);

    @media screen and (max-width: $min-md - 1) {
        font-size: 13px;
        letter-spacing: -0.13px;
    }
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    content: "";
    border-top-color: $light-blue;
}

.tooltip.show {
    opacity: 1 !important;
}

// tooltip button
.tooltip-btn {
    border: none;
    background: transparent;

    img {
        width: 18px;
        height: 18px;

        @media screen and (max-width: $min-md - 1) {
            width: 16px;
            height: 16px;
        }
    }

    &:focus {
        outline: none;
    }
}

.custom-tooltip {
    position: relative;
    display: inline-block;
    margin-right: 6px;
    line-height: 16px;
  
    img {
      width: 22px;
    }
  
    &:hover {
      .tooltip-text {
        visibility: visible;
      }
    }
    .tooltip-text {
      font-family: 'cairo-regular', sans-serif;
      bottom: 28px;
      visibility: hidden;
      width: 270px;
      background-color: $blue-primary;
      color: $white;
      text-align: right;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      font-size: 14px;
      left: 50%;
      padding: 10px;
      margin-left: -136px;
  
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #0885c2 transparent transparent transparent;
      }
    }
}