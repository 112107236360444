.modal {
  background: rgba($color: $grey, $alpha: 0.5);
  padding: 0px !important;

  .modal-dialog {
    max-width: 712px;

    @media screen and (max-width: $min-lg - 1) {
      max-width: unset;
    }

    .modal-content {
      padding: 26px;
      border-radius: 10px;
      border: 0px;
      background: $white;
      box-shadow: 0px 2px 2px rgba($black, 0.08);

      @media screen and (max-width: $min-lg - 1) {
        padding: 12px;
      }

      .modal-head {
        font-size: 18px;
        letter-spacing: -0.18px;
        color: $primary;
        line-height: 33px;
        margin-bottom: 20px;

        @media screen and (max-width: $min-lg - 1) {
          font-size: 16px;
          letter-spacing: -0.16px;
          line-height: 30px;
          margin-bottom: 12px;
        }
      }

      .modal-subhead {
        font-size: 14px;
        letter-spacing: -0.14px;
        color: $primary;
        line-height: 26px;
        margin-bottom: 20px;

        @media screen and (max-width: $min-lg - 1) {
          font-size: 13px;
          letter-spacing: -0.13px;
          line-height: 24px;
          margin-bottom: 12px;
        }
      }

      .modal-body {
        padding: 0px;

        .modal-title {
          font-size: 16px;
          letter-spacing: -0.16px;
          color: $primary;
          line-height: 30px;
          margin-top: 26px;

          @media screen and (max-width: $min-lg - 1) {
            margin-bottom: 6px;
          }
        }

        .modal-subtitle {
          color: $dark-grey;
          font-size: 14px;
          letter-spacing: -0.14px;
          font-family: "cairo-regular", sans-serif;
          line-height: 36px;
          margin-bottom: 0px;

          @media screen and (max-width: $min-lg - 1) {
            line-height: 30px;
            font-size: 13px;
            letter-spacing: -0.13px;
          }
        }

        .modal-done {
          text-align: center;

          img {
            @media screen and (max-width: $min-lg - 1) {
              width: 75px;
              height: 75px;
            }
          }

          .modal-done-msg {
            font-size: 14px;
            letter-spacing: -0.14px;
            color: $primary;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 0px;
            font-family: "cairo-semibold", sans-serif;

            @media screen and (max-width: $min-lg - 1) {
              font-size: 13px;
              letter-spacing: -0.13px;
              margin-top: 12px;
            }
          }
        }

        .modal-msg {
          font-size: 16px;
          letter-spacing: -0.16px;
          color: $primary;
          line-height: 26px;
          margin-bottom: 0px;
          font-family: "cairo-semibold", sans-serif;
          text-align: center;

          @media screen and (max-width: $min-lg - 1) {
            font-size: 14px;
            letter-spacing: -0.14px;
          }
        }

        .content-img {
          text-align: center;

          img {
            border-radius: 10px;
          }
        }

        .note-editor .note-editing-area .note-editable {
          height: 200px !important;
        }
      }

      .actions-single {
        justify-content: center;
      }

      .actions-double {
        @media screen and (max-width: $min-sm - 1) {
          .btn {
            min-width: 135px;
          }
        }
      }

      .actions-single,
      .actions-double {
        margin-top: 26px;

        @media screen and (max-width: $min-lg - 1) {
          margin-top: 20px;
        }
      }

      .btn {
        box-shadow: none !important;
      }
    }
  }

  &.modal-xs {
    .modal-dialog {
      width: 480px;

      @media screen and (max-width: $min-lg - 1) {
        width: auto;
      }
    }
  }
}

.whatsnew-dialog-container {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.rating-dialog-container {

  .dialog-container{
    
    .mat-mdc-dialog-content{
      margin: 0;
      max-height: unset;
      overflow: initial;
    }
  }
}

.dialog-container {
  width: 470px;

  .mat-mdc-dialog-title {
    font-family: 'cairo-bold', sans-serif;
    font-size: 16px;
  }

  .mat-mdc-dialog-content {
    overflow: hidden;
    .dialog-message {
      font-size: 16px;
      font-family: 'cairo-regular', sans-serif;
      margin: 26px 0 0;
    }

    label {
      font-family: 'cairo-bold';
      font-size: 14px;
    }
  }

  .mat-mdc-dialog-actions {
    &.actions-single {
      justify-content: center;
    }

    &.actions-double {
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn {
        font-family: 'cairo-bold' !important;
      }
    }
  }
}

.mat-mdc-dialog-container:has(.avatar-dialog ) {
  padding: 0 !important;
}

.avatar-dialog.mat-mdc-dialog-content {
  margin: 0;
  padding: 0;
  height: 468px;
  width: fit-content;
}
