.setting-layout {

    .main-card {
        &.setting-body {
            padding: 0px;

            .page-title {
                padding: 26px;
                padding-left: 0px;
                margin-bottom: 0px;

                @media screen and (max-width: $min-md - 1) {
                    padding: 12px;
                }
            }
        }
    }

    // setting main page
    .setting-list-conatiner {
        .setting-list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $light-grey-hover;
            border-bottom: 1px solid $light-grey-hover;
            padding: 16px 26px;
            margin-top: -1px;
            color: $primary;

            @media screen and (max-width: $min-md - 1) {
                padding: 13px 12px;
            }

            &:hover {
                text-decoration: none;
                background: $light-grey-hover;
            }

            h6 {
                margin: 0;
                font-size: 14px;
                letter-spacing: -0.14px;

                @media screen and (max-width: $min-md - 1) {
                    font-size: 13px;
                    letter-spacing: -0.13px;
                }
            }

            .list-arrow {
                display: flex;
            }
        }
    }

    .second-list {
        .page-title {
            padding: 37px 26px 22px 0px !important;

            @media screen and (max-width: $min-md - 1) {
                padding: 24px 12px 12px 0px !important;
            }
        }

        .setting-list-conatiner {
            .setting-list-item {
                &:last-child {
                    border: 0px;
                    border-radius: 0px 0px 10px 10px;
                }
            }
        }
    }

    // setting personal info.
    .setting-container {
        padding-bottom: 10px;

        @media screen and (max-width: $min-md - 1) {
            padding: 12px;
            padding-bottom: 4px;
        }

        label {
            font-size: 14px;
            letter-spacing: -0.14px;
            color: $primary;

            @media screen and (max-width: $min-md - 1) {
                font-size: 13px;
                letter-spacing: -0.13px;
            }
        }

        .form-group {
            margin-bottom: 16px;

            @media screen and (max-width: $min-md - 1) {
                margin-bottom: 10px;
            }
        }

        .col-md-6{
            @media screen and (max-width: $min-md - 1) {
                padding: 0px;
            }
        }

        .border-full-line{
            @media screen and (max-width: $min-xs - 1) {
                margin: 4px -26px 14px;
            }

            @media screen and (max-width: $min-xs - 1) {
                width: 114%;
            }
        }
    }

    .setting-form-action {
        display: flex;
        justify-content: space-between;
        margin-top: 26px;

        @media screen and (max-width: $min-md - 1) {
            margin-top: 16px;
        }

        .btn {
            min-width: 184px;

            @media screen and (max-width: $min-sm - 1) {
                min-width: 145px;
            }
        }
    }
}