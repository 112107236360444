.note-editor {
    background: transparent;
     border:none !important;
     font-size: 14px !important;
     font-family: 'cairo-regular', sans-serif !important;
    @media screen and (max-width: $min-md - 1px) {
        overflow-x: hidden;
    }

    // &.note-frame{
    //     border: 0px !important; 
    //     background: transparent;
    // }

    // toolbar
    // .note-toolbar {
    //     overflow-x: auto;
    //     overflow-y: hidden;
    //     display: flex;
    //     align-items: center;
    //     flex-wrap: nowrap;
    //     z-index: 1;
    //     background: transparent linear-gradient(0deg, #F1F3F7 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important;
    //     border: 1px solid $light-grey-2;
    //     border-radius: 10px 10px 0px 0px;

    //     __<<ngThemingMigrationEscapedComment0>>__
    //     -ms-overflow-style: none;

    //     __<<ngThemingMigrationEscapedComment1>>__
    //     scrollbar-width: none;

    //     __<<ngThemingMigrationEscapedComment2>>__
    //     &::-webkit-scrollbar {
    //         display: none;
    //     }

    //     &.card-header {
    //         border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    //         background: transparent;
    //         height: 50px;
    //         padding: 0px;

    //         display: none;

    //         @media screen and (max-width: $min-md - 1px) {
    //             height: 40px;
    //         }
    //     }

    //     .note-btn {
    //         border-radius: 0px;
    //         border: none;
    //         box-shadow: none;
    //         background: transparent;
    //         min-width: 20px;
    //         padding: 0px 1rem;
    //         height: 50px;

    //         &:focus {
    //             background: transparent;
    //         }

    //         &:active {
    //             padding: 0px 1rem !important;
    //         }

    //         i {
    //             color: $dark-grey;
    //             font-size: 16px;
    //         }

    //         &[aria-label="Paragraph"] {
    //             display: none;
    //         }
    //     }

    //     .note-btn-group {
    //         margin: 0px;

    //         .btn-group {
    //             .note-btn {

    //                 &.dropdown-toggle {
    //                     &::after {
    //                         display: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     // style align elements out of dropdown
    //     .dropdown-menu {
    //         &.note-dropdown-menu {
    //             display: block;
    //             top: 0px;
    //             right: 0;
    //             border: none;
    //             background: #f1f3f7;
    //             padding: 0;
    //             margin-top: 0px;
    //             min-width: auto;

    //             .note-list {
    //                 display: none;
    //             }

    //             .note-align {
    //                 margin-right: 0px;
    //             }
    //         }
    //     }
    // }

    // edit area
    .note-editing-area {
        .note-editable {
            background: #FAFAFB 0% 0% no-repeat padding-box !important;
            height: 300px !important;
            // color: $dark-grey !important;
            // font-family: "cairo-regular" !important;
            // font-size: 16px !important;
            letter-spacing: -0.14px !important;
            padding: 16px;
            border: 1px solid $light-grey-2;
            border-radius: 10px;
            // text-align: right !important;

            ul {
                list-style: inside;
            }

            p {
                margin-bottom: 0px;
                // text-align: right !important;
            }
            span {
                display: inline-block;

            }
            pre , div , p , span {
                padding: 0px !important;
                font-family: "cairo-regular" !important;
                border: 0px !important;
                background: transparent !important;
                // color: $dark-grey !important;
                overflow: hidden !important;
                // font-size: 16px !important;
                // text-align: right !important;
                code{
                    // font-family: "cairo-regular" !important;
                }
            }
        }
    }

    .note-statusbar {
        display: none;
    }
}

.summernote-disable {
    .note-editor {
        .note-editing-area {
            .note-editable {
                background: transparent !important;
                height: auto !important;
                pointer-events: none !important;
                border: 0px !important;
                padding: 0px !important;
                overflow: hidden !important;
                // font-family: "cairo-regular" !important;

                p{
                    // font-family: "cairo-regular" !important;
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}
