.header-report {
    margin-bottom: 40px;

    .header-report-flex {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        .logo {
            .navbar-brand {
                margin: 0px;
                padding: 0px;

                img {
                    height: 112px;
                }
            }
        }

        .report-subjects {
            // margin-left: 11px;
            // margin-right: 15px;
            table {
                &.table {
                    tbody {
                        tr {
                            td {
                                border-top: 0;
                                padding: 2px 0px;
                                color: $grey;
                                font-size: 14px;
                                // letter-spacing: -.14px;
                                min-width: auto;
                                line-height: 20px;

                                &.subject-name{
                                    padding: 2px 4px 0px 40px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    @media screen and (max-width: $min-md - 1) {
        margin-bottom: 26px;

        .header-report-flex {
            margin-top: 16px;
    
            .logo {
                .navbar-brand {
                    img {
                        height: 50px;
                    }
                }
            }
    
            .report-subjects {
                margin-left: 0px;

                table {
                    &.table {
                        tbody {
                            tr {
                                td {
                                    border-top: 0;
                                    color: $grey;
                                    font-size: 12px;
                                    // letter-spacing: -.12px;
                                    line-height: 17px;

                                    &.subject-name{
                                        padding: 2px 4px 0px 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.report-layout {
    background: $white;

    @media screen and (max-width: $min-md - 1) {
        .container {
            padding: 0px;

            .row {
                margin: 0px;

                .col-lg-9 {
                    padding: 0px 10px;
                }
            }
        }
    }

    .report-table-title {
        font-size: 17px;
        // letter-spacing: -0.17px;
        line-height: 32px;
        color: $primary;
        margin: 30px 0px 6px;

        @media screen and (max-width: $min-md - 1) {
            font-size: 16px;
            // letter-spacing: -0.16px;
            line-height: 30px;
            margin: 20px 0px 6px;
        }
    }

    .report-header {
        display: flex;
        flex-wrap: nowrap;

        .banner img{
            border-radius: 10px;
            height: 270px;
            min-width: 270px;
        }

        .header-data {
            min-width: calc(100% - 380px);
            margin-right: 30px;

            h2 {
                font-size: 22px;
                // letter-spacing: 0px;
                line-height: 42px;
                margin-bottom: 16px;
            }

            p {
                font-size: 14px;
                // letter-spacing: -0.14px;
                line-height: 26px;
                color: $dark-grey;
                margin-top: -6px;
                margin-bottom: 20px;
                font-family: "cairo-semibold", sans-serif;
            }

            .req-body {
                .req-row {
                    padding: 0px;
                    border-bottom: 0px;

                    .req-box,
                    .req-full-box {
                        margin-bottom: 8px;
                    }

                    &.req-notes {
                        margin-top: 6px;

                        .req-box {
                            margin-bottom: 10px;

                            .req-data {
                                min-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $min-lg - 1) {
            flex-wrap: wrap;

            .banner img{
                margin: auto;
                margin-bottom: 12px;
                height: 400px;
                min-width: 100%;
            }

            .header-data {
                min-width: 100%;
                margin-right: 0px;
                
                .req-body{
                    .req-row{
                        &.req-notes{
                            .req-box{
                                &:last-child{
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $min-md - 1) {
            padding: 10px;
            padding-bottom: 0px;

            .header-data {

                h2 {
                    font-size: 16px;
                    line-height: 30px;
                    margin-bottom: 12px;
                }

                .req-body {
                    .req-row {

                        .req-box,
                        .req-full-box {
                            margin-bottom: 8px;
                        }

                        .req-full-box {
                            flex-wrap: nowrap;

                            .req-title {
                                min-width: 50%;
                                margin-bottom: 0px;
                            }

                            .req-data {
                                min-width: 50%;
                                text-align: left;
                            }
                        }

                        &.req-notes {
                            margin-top: 4px;

                            .req-box {
                                margin-bottom: 12px;

                                .req-data {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $min-xs - 1) {
            .banner img{
                height: 300px;
                min-width: 300px;
            }
        }
    }

    .border-line {
        background: $border-grey;
        height: 2px;
        margin-top: 30px;

        @media screen and (max-width: $min-md - 1) {
            margin: auto;
            margin-top: 20px;
            width: 96%;
        }

        @media screen and (max-width: $min-xs - 1) {
            width: 92%;
        }
    }

    .report-title {
        background: $yellow;
        border-radius: 10px;
        font-size: 17px;
        // letter-spacing: -0.2px;
        line-height: 32px;
        color: $primary;
        padding: 14px 20px;
        box-shadow: 0px 2px 2px rgba($black, 0.08);
        margin: 30px 0px 20px;

        @media screen and (max-width: $min-md - 1) {
            font-size: 16px;
            // letter-spacing: -0.16px;
            line-height: 30px;
            padding: 10px 12px;
            margin: 20px 0px 10px;
        }
    }

    .report-subtitle {
        font-size: 16px;
        // letter-spacing: -0.16px;
        margin-bottom: 10px;

        @media screen and (max-width: $min-lg - 1px) {
            font-size: 13px;
            // letter-spacing: -0.13px;
            margin-bottom: 6px;
        }
    }

    .report-chart {
        margin: 30px 0px;

        .report-table-title {
            margin-bottom: 33px;

            @media screen and (max-width: $min-md - 1) {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        canvas {
            height: 370px !important;
        }

    }

    .images-grid {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 10px;

        .image-card {
            width: 24%;
            margin-bottom: 30px;
            margin-left: 30px;
            max-width: 270px;

            &:nth-child(4n) {
                margin-left: 0px;
            }

            .banner img{
                border-radius: 10px;
                height: 270px;
                margin-bottom: 10px;
            }

            .image-card-title {
                color: $primary;
                line-height: 26px;
                font-size: 14px;
                // letter-spacing: -0.14px;
                margin-bottom: 3px;
                span{
                    margin-right: 5px;
                }
            }
            .image-card-report{
                .date{
                    font-size: 14px;
                    color: #707585;
                    font-family: 'cairo-regular', sans-serif;
                }
            }

            .image-card-data {
                span {
                    color: $dark-grey;
                    font-size: 14px;
                    // letter-spacing: -0.14px;
                    line-height: 26px;
                    font-family: "cairo-regular", sans-serif;
                    margin-left: 6px;

                    &:last-child {
                        margin-left: 0px;
                    }
                }
            }

            .image-card-footer {
                font-family: "cairo-regular", sans-serif;
                color: $error;
                font-size: 14px;
                line-height: 26px;
            }
        }

        @media screen and (max-width: $min-xl - 1) {
            .image-card {
                width: 23%;
                margin-left: 24px;
            }
        }

        @media screen and (max-width: $min-lg - 1) {
            margin-top: 22px;
            margin-bottom: 6px;

            .image-card {
                min-width: 32%;
                width: 32%;
                margin-bottom: 16px;
                margin-left: 14px;

                &:nth-child(3n) {
                    margin-left: 0px;
                }

                &:nth-child(4n) {
                    margin-left: 14px;
                }
            }
        }

        @media screen and (max-width: $min-md - 1) {
            margin-top: 22px;
            margin-bottom: 6px;

            .image-card {
                min-width: 32%;
                margin-bottom: 16px;
                margin-left: 11px;

                &:nth-child(3n) {
                    margin-left: 0px;
                }

                &:nth-child(4n) {
                    margin-left: 11px;
                }

                .banner img{
                    border-radius: 10px;
                    height: 200px;
                    margin-bottom: 4px;
                }

                .image-card-title {
                    line-height: 24px;
                    font-size: 13px;
                    // letter-spacing: -0.13px;
                    margin-bottom: 0px;
                }

                .image-card-data {
                    span {
                        font-size: 13px;
                        // letter-spacing: -0.13px;
                        line-height: 24px;
                        margin-left: 4px;

                        &:last-child {
                            margin-left: 0px;
                        }
                    }
                }

                .image-card-footer {
                    font-size: 13px;
                    // letter-spacing: -0.13px;
                    line-height: 24px;
                }
            }
        }

        @media screen and (max-width: $min-sm - 1) {
            .image-card {
                min-width: 48%;
                margin-left: 12px;

                &:nth-child(even) {
                    margin-left: 0px;
                }

                &:nth-child(odd) {
                    margin-left: 12px;
                }

                .banner img{
                    border-radius: 10px;
                    height: 145px;
                    margin-bottom: 4px;
                }
            }
        }
    }

    // report msg fixed
    .report-msg-fixed {
        background-color: $white;
        padding: 26px 16px;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba($black, 0.08);
        border: 1px solid $line-grey;
        position: fixed;
        bottom: 16px;
        z-index: 1000;
        margin: auto;
        width: 1170px;

        @media screen and (max-width: $min-xl - 1px) {
            padding: 20px 0px;
            width: auto;
            right: 15px;
            left: 15px;
        }

        @media screen and (max-width: $min-md - 1px) {
            padding: 20px 0px;
            width: auto;
            right: 10px;
            left: 10px;
        }

        .container-fluid {
            @media screen and (max-width: $min-md - 1px) {
                padding: 0px;
            }
        }

        .col-lg-6 {
            @media screen and (max-width: $min-md - 1px) {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        h3 {
            font-size: 14px;
            // letter-spacing: -0.14px;
            color: $primary;
            padding: 0px;
            margin: 0px;
            font-family: "cairo-bold", sans-serif;

            @media screen and (max-width: $min-lg - 1px) {
                margin-bottom: 16px;
                font-family: "cairo-semibold", sans-serif;
                font-size: 13px;
                // letter-spacing: -0.13px;
            }
        }

        .report-content-buttons {
            display: flex;
            justify-content: flex-end;

            button {
                margin-right: 16px;
                min-width: 200px;
                box-shadow: none;
            }

            @media screen and (max-width: $min-lg - 1px) {
                justify-content: center;
            }

            @media screen and (max-width: $min-sm - 1px) {
                button {
                    min-width: 133px;
                }
            }

            @media screen and (max-width: $min-xs - 1px) {
                justify-content: space-between;

                button {
                    margin-right: 0px;
                }
            }
        }
    }

    // report range result
    .report-range-result {
        display: flex;
        align-items: center;

        h3 {
            font-size: 14px;
            // letter-spacing: -0.2px;
            color: $primary;
            margin: 0px;

            @media screen and (max-width: $min-md - 1px) {
                font-size: 13px;
            }
        }

        .range-result {
            font-family: "cairo-semibold", sans-serif;
            color: $dark-grey;
            font-size: 14px;
            // letter-spacing: -0.2px;
            padding: 0px 1rem;

            @media screen and (max-width: $min-md - 1px) {
                font-size: 13px;
            }
        }
    }

    // final report range result
    .final-progress-result {
        .report-range-result {
            h3 {
                font-size: 16px;

                @media screen and (max-width: $min-md - 1px) {
                    font-size: 13px;
                }
            }

            .range-result {
                font-size: 16px;

                @media screen and (max-width: $min-md - 1px) {
                    font-size: 14px;
                }
            }
        }

        .progress-box {
            height: 58px !important;

            .progess-icon {
                min-width: 60px !important;
                padding: 8px 10px !important;

                @media screen and (max-width: $min-md - 1px) {
                    min-width: 50px !important;
                    padding: 8px !important;
                }
            }

            @media screen and (max-width: $min-md - 1px) {
                height: 50px !important;
            }
        }
    }

    // progress bar container
    .progress-container {
        @media screen and (max-width: $min-md - 1px) {
            margin-top: -14px;
        }

        .col-md-6,
        .col-12 {
            @media screen and (max-width: $min-md - 1px) {
                padding-right: 0px;
                padding-left: 0px;
            }
        }

        .progress-box {
            display: flex;
            flex-wrap: nowrap;
            height: 50px;
            margin-bottom: 20px;

            @media screen and (max-width: $min-md - 1px) {
                height: 40px;
                margin-bottom: 10px;
            }

            .progess-icon {
                min-width: 50px;
                background: $white;
                text-align: center;
                padding: 10px 0rem;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid $line-grey;
                border-left: 0px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: $min-md - 1px) {
                    min-width: 40px;
                    padding: 8px 0px;
                }

                img {
                    width: 30px;
                    height: 30px;

                    @media screen and (max-width: $min-md - 1px) {
                        width: 24px;
                        height: 24px;
                    }
                }

                .progress-value {
                    color: $dark-grey;
                    font-family: "cairo-semibold", sans-serif;
                    font-size: 24px;
                    // letter-spacing: -0.4px;

                    @media screen and (max-width: $min-md - 1px) {
                        font-size: 20px;
                        // letter-spacing: -0.2px;
                    }
                }
            }
        }
    }

    // progress bar style and color
    .progress {
        width: 100%;
        border-radius: 10px 0px 0px 10px;
        border: 1px solid $line-grey;
        border-right: 0px;
        height: auto;
        background: $progress-bg;

        .progress-bar {
            &._bar-orange {
                background: #F49C6C;
            }

            &._bar-dark-green {
                background: #40B58D;
            }

            &._bar-green {
                background: #95C675;
            }

            &._bar-yellow {
                background: #FFD059;
            }

            &._bar-red {
                background: #D9727F;
            }

            &._bar-pink {
                background: #E48377;
            }
        }
    }

    // report notes
    .report-notes {
        padding-top: 30px;

        @media screen and (max-width: $min-md - 1px) {
            padding-top: 16px;
        }

        h5 {
            font-size: 16px;
            // letter-spacing: -0.16px;
            margin-bottom: 10px;

            @media screen and (max-width: $min-lg - 1px) {
                font-size: 13px;
                // letter-spacing: -0.13px;
                margin-bottom: 6px;
            }
        }

        p {
            color: #707585 !important;
            font-size: 14px !important;
            font-family: "cairo-regular", sans-serif !important;
            // letter-spacing: -0.14px;
            line-height: 26px !important;
            margin-bottom: 10px;
            list-style-type: decimal;
            display: list-item;
            list-style-position: inside;
            width: 100% !important;
            @media screen and (max-width: $min-lg - 1px) {
                font-size: 13px;
                // letter-spacing: -0.13px;
                line-height: 24px;
            }
        }

        ul {
            padding-right: 34px;
            margin-bottom: 0px;

            @media screen and (max-width: $min-lg - 1px) {
                padding-right: 20px;
            }

            li {
                color: $dark-grey;
                font-size: 14px;
                font-family: "cairo-semibold", sans-serif;
                // letter-spacing: -0.14px;
                line-height: 26px;

                @media screen and (max-width: $min-lg - 1px) {
                    font-size: 13px;
                    // letter-spacing: -0.13px;
                    line-height: 24px;
                }
            }
        }
    }

    // report table
    .report-table {
        margin-top: 16px;

        @media screen and (max-width: $min-lg - 1px) {
            margin-left: -15px;
            margin-top: 0px;
        }

        table {
            border-top: 0px;

            thead {
                background: transparent;

                th {
                    // border-top: 0px !important;
                    // border-bottom: 0px !important;
                    // font-size: 16px;
                    // // letter-spacing: -0.18px;
                    // color: $dark-grey;
                    // // padding-right: 20px;
                    // // height: 54px;
                    // background: transparent;

                    // @media screen and (max-width: $min-lg - 1px) {
                    //     font-size: 13px;
                    //     padding-right: 0px;
                    // }
                }
            }

            tbody {
                background: transparent;

                tr {
                    &:first-child{
                        border-top: 2px solid $border-grey;
                    }

                    th {
                        // font-size: 14px;
                        // color: $primary;
                        // // letter-spacing: -0.14px;
                        // min-width: 140px;
                        // padding-right: 20px;
                        // vertical-align: top;
                        // border-top: 1px solid $border-grey;

                        // @media screen and (max-width: $min-lg - 1px) {
                        //     font-size: 13px;
                        //     min-width: 100px;
                        //     padding-right: 0px;
                        // }
                    }

                    td {
                        min-width: 196px;
                        border-top: 1px solid $border-grey !important;
                        vertical-align: top;
                        padding-right: 20px;
                        padding-left: 16px;

                        @media screen and (max-width: $min-lg - 1px) {
                            min-width: 120px;
                            padding-right: 0px;
                            padding-left: 0px;
                        }

                        &.notes-row{
                            min-width: 340px;
                        }

                        ol.list-numeric{
                            padding-right: 0px !important;
                        }

                        h6 {
                            font-size: 16px;
                            color: $dark-grey;
                            font-family: "cairo-bold", sans-serif;
                            // letter-spacing: -0.16px;
                            margin-bottom: 0px;

                            @media screen and (max-width: $min-md - 1px) {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .progress {
            height: 30px;
            max-width: 170px;
            border-radius: 10px;
            background: $progress-bg;
            border: 1px solid $line-grey;
            position: relative;

            @media screen and (max-width: $min-lg - 1px) {
                height: 24px;
                max-width: 102px;
                border-radius: 5px;
            }

            .progress-layer {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                // letter-spacing: -0.13px;
                line-height: 28px;
                color: #707585;

                @media screen and (max-width: $min-lg - 1px) {
                    font-size: 12px;
                }
            }
        }

        .trouble-data {
            display: flex;
            flex-wrap: nowrap;

            h5 {
                color: $error;
                margin-bottom: 0px;
                font-size: 14px;
                // letter-spacing: -0.16px;
                font-family: "cairo-bold", sans-serif;

                @media screen and (max-width: $min-lg - 1px) {
                    font-size: 13px;
                }
            }

            p {
                color: $dark-grey;
                margin-bottom: 0px;
                padding-right: 26px;
                font-family: "cairo-regular", sans-serif;
                font-size: 14px;
                // letter-spacing: -0.16px;
                line-height: 20px;

                @media screen and (max-width: $min-xl - 1px) {
                    min-width: 400px;
                }

                @media screen and (max-width: $min-lg - 1px) {
                    font-size: 13px;
                }
            }
        }
    }

    .mx-10 {
        @media screen and (max-width: $min-md - 1) {
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    .ml-10{
        @media screen and (max-width: $min-md - 1) {
            margin-right: 0px;
            margin-left: 10px;
        }
    }
}

// add margin to footer in report page for show down page content
.margin-scroll{
    @media screen and (max-width: $min-md - 1) {
        margin-top: 140px !important;
    }
}

*{
    letter-spacing: 0 !important;
}

.summernote-container {
   
    word-wrap: break-word;
    font-family: 'cairo-regular', sans-serif;
    font-size: 14px;
    div{
        margin-bottom: 10px !important;   
        color: #707585 !important;     
        font-family: 'cairo-regular', sans-serif;
        font-size: 14px;
        background-color: transparent !important;
        line-height: 26px;
        font-weight: 100 !important;
    }
    span{
        font-weight: 100 !important;
        color: #707585 !important;
        background-color: transparent !important;
        font-size: 14px !important;
        font-family: 'cairo-regular', sans-serif !important;
        line-height: 26px !important;
        margin-bottom: 10px !important;
    }
}