.main-card {
  background: $white;
  padding: 26px;
  margin: 90px 30px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba($black, 0.08);

  @media screen and (max-width: $min-lg - 1) {
    padding: 12px;
    margin-bottom: 14px;
  }
}

.single-card {
  background: $white;
  padding: 15px 26px 0;
  margin: 20px 30px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba($black, 0.08);
}

.table-card {
  background: #ffffff;
  padding: 16px 30px;
  margin-bottom: 22px;
  border: 1px solid #DEE1EC;
  border-radius: 5px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .card-title {
    font-size: 15px;
    font-family: 'cairo-bold', sans-serif;
    width: 100%;
  }

  .request-table {
    flex-grow: 1;
    padding: 0 !important;

    .table-responsive {
      height: 350px;
    }
  }
}

.mini-card {
  display: flex;
  background: #ffffff;
  padding: 16px 30px;
  border: 1px solid #DEE1EC;
  border-radius: 5px;

  img {
    margin-left: 15px;
  }

  p {
    margin-bottom: 0 !important;

    &.title {
      font-size: 14px;
    }

    &.data {
      font-size: 24px;

      &.blue {
        color: #0774A9;
      }

      &.green {
        color: #379B79;
      }

      &.red {
        color: #C06570;
      }
    }
  }
}

.avatar-card {
  text-align: center;

  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  .avater-name {
    color: $primary;
    margin: 0px;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 26px;
  }

  @media screen and (max-width: $min-lg - 1) {
    display: none;
  }
}

.home-card {
  padding: 14px 26px 30px;

  @media screen and (max-width: $min-md - 1) {
    margin-bottom: 12px;
  }

  .home-card-number {
    font-size: 36px;
    color: $primary;
    line-height: 60px;
    margin-bottom: 0px;

    span {
      font-size: 14px;
      letter-spacing: -0.14px;
      color: $primary;
      line-height: 26px;
    }
  }

  .home-card-status {
    color: $grey;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 26px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: $min-md - 1) {
    padding: 8px 15px 15px;

    .home-card-number {
      font-size: 20px;
      letter-spacing: -0.2px;
      line-height: 37px;

      span {
        font-size: 12px;
        letter-spacing: -0.12px;
        color: $primary;
        line-height: 23px;
      }
    }

    .home-card-status {
      font-size: 13px;
      letter-spacing: -0.13px;
      line-height: 24px;

    }
  }
}

// refund and additional amount view 

.sub-content {
  border: 1px solid #DEE1EC;
  border-radius: 5px;
  background-color: $white-secondary ;
  padding: 16px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  position: relative;
  margin-bottom: 16px;

  .col {
    margin-bottom: 12px;

    h4 {
      color: #A0A3AE;
      font-size: 14px;
      font-family: 'cairo-regular', sans-serif;
    }

    p {
      color: #111934;
      font-size: 14px;
      font-family: 'cairo-semibold', sans-serif;
      margin-bottom: 0 !important;
    }
  }

  .refund-btn {
    position: absolute;
    left: 16px;
    bottom: 20px;

    .btn-green {
      min-width: 70px !important;
      height: 24px !important;
    }
  }
}

.custom-card {
  background: $white;
  padding: 26px;
  margin: 0 35px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 8%);

  .empty-services {
    font-size: 14px;
    color: #232323;
    font-family: 'cairo-regular', sans-serif;
  }

  .custom-container {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;

    .item {
      display: flex;
      flex: calc(50% - 5px);
      flex-grow: 0;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $grey-5;
      background-color: $grey-6;
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
      color: #232323;
      font-family: 'cairo-regular', sans-serif;

      .setting-container {
        display: flex;

        .setting-item {
          display: inline-flex;
          height: 18px;
          width: 16px;
          cursor: pointer;

          &.delete {
            background: url('../../../assets/images/svg/delete-icon-ins.svg');
            background-repeat: no-repeat;

            &:hover {
              background: url('../../../assets/images/svg/grid-delete-icon.svg');

            }
          }

          &.wrench {
            margin-left: 10px;
          }
        }

      }
    }
  }
}
