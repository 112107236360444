// drag images container
.images-container {
    display: flex;
    margin-left: -26px;

    @media screen and (max-width: $min-lg - 1) {
        margin-left: -12px;
    }

    .swiper-container{
        margin-left: 0px;
        margin-right: 0px;
    }

    .drag-btn {
        min-width: 100px;
        height: 100px;
        margin-left: 12px;

        @media screen and (max-width: $min-md - 1) {
            min-width: 80px;
            height: 80px;
        }

        .add-new {
            height: 100%;
            padding: 0px;
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
        }
        
        button {
            padding: 0px;

            img {
                width: 24px;
                height: 24px;

                @media screen and (max-width: $min-md - 1) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .images-body-scroll {
        display: flex;
        flex-wrap: nowrap;

        .drag-image-box {
            min-width: 100px;
            min-height: 100px;
            width: 100px;
            height: 100px;
            border-radius: 10px;
            margin-left: 12px;
            cursor: pointer;
            position: relative;

            @media screen and (max-width: $min-md - 1) {
                min-width: 80px;
                min-height: 80px;
                width: 80px;
                height: 80px;
            }

            img {
                height: 100%;
                width: 100%;
                border-radius: 10px;
            }

            .drag-image-layer {
                background: rgba($color: $primary, $alpha: .5);
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100%;
                height: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                img{
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: $min-md - 1) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            &:hover .drag-image-layer {
                display: flex;
            }
        }
    }
}